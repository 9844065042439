const isProjectsAndBuildTypesEnabled =
  window.internalProps['teamcity.pipelines.projects.enabled'] !== false
export const isPipelinesEnabled = window.internalProps['teamcity.pipelines.enabled']
export const isPipelinesEnabledInExclusiveMode =
  isPipelinesEnabled && !isProjectsAndBuildTypesEnabled
export const areFeaturesWithoutBackendEnabled =
  window.internalProps['teamcity.pipelines.featuresWaitingForBackend']
export const isDockerAutocompleteEnabled =
  window.internalProps['teamcity.docker.dockerhub.autocomplete']
export const isPipelinesDockerfileEnabled =
  window.internalProps['teamcity.pipelines.dockerfile.support']
