import type {EntryPoint, EntryPointComponent} from 'react-relay'

import {makeResource} from '../../../../utils/makeResource'
import type {AgentsPagesQueries} from '../AgentsPages.queries'
import {agentsPagesQueries} from '../AgentsPages.queries'

export const UnauthorizedAgentsPageEntryPoint: EntryPoint<
  EntryPointComponent<AgentsPagesQueries, {}>
> = {
  root: makeResource(
    'UnauthorizedAgentsPage',
    () =>
      import(
        /* webpackChunkName: "UnauthorizedAgentsPage", webpackPrefetch: true */ './UnauthorizedAgentsPage'
      ),
  ),
  getPreloadProps: () => ({queries: agentsPagesQueries}),
}
