/**
 * @generated SignedSource<<db6e007349101fe41eacd3c473ce8a2d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AvatarEditorQuery$variables = {
  userLocator: string;
};
export type AvatarEditorQuery$data = {
  readonly user: {
    readonly avatars: {
      readonly urlToSize20: string | null;
    } | null;
    readonly name: string | null;
    readonly username: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"AvatarFragment">;
  } | null;
};
export type AvatarEditorQuery = {
  response: AvatarEditorQuery$data;
  variables: AvatarEditorQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userLocator"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "userLocator",
    "variableName": "userLocator"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "urlToSize20",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AvatarEditorQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AvatarFragment"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserAvatars",
            "kind": "LinkedField",
            "name": "avatars",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AvatarEditorQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rawId",
            "storageKey": null
          },
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserAvatars",
            "kind": "LinkedField",
            "name": "avatars",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "urlToSize28",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "urlToSize32",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "urlToSize40",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "urlToSize56",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "urlToSize64",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "urlToSize80",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8d97adb9d61c2c879c7925ae758ec65d",
    "id": null,
    "metadata": {},
    "name": "AvatarEditorQuery",
    "operationKind": "query",
    "text": "query AvatarEditorQuery(\n  $userLocator: String!\n) {\n  user(userLocator: $userLocator) {\n    ...AvatarFragment\n    avatars {\n      urlToSize20\n    }\n    name\n    username\n    id\n  }\n}\n\nfragment AvatarFragment on User {\n  rawId\n  name\n  username\n  avatars {\n    urlToSize20\n    urlToSize28\n    urlToSize32\n    urlToSize40\n    urlToSize56\n    urlToSize64\n    urlToSize80\n  }\n}\n"
  }
};
})();

(node as any).hash = "13dedd19107d22143a897c2f55980c6f";

export default node;
