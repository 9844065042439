import type {EntryPoint} from 'react-relay'
import {getRequest} from 'relay-runtime'

import {makeResource} from '../../../../utils/makeResource'

import type {BuildTestsTabComponent} from './BuildTestsTab'
import {countsQuery, getCountsVariables} from './BuildTestsTab.queries'

export const BuildTestsTabEntryPoint: EntryPoint<BuildTestsTabComponent, string> = {
  root: makeResource(
    'BuildTestsTab',
    () => import(/* webpackChunkName: "BuildTestsTab", webpackPrefetch: true */ './BuildTestsTab'),
  ),
  getPreloadProps: buildLocator => ({
    queries: {
      counts: {parameters: getRequest(countsQuery), variables: getCountsVariables(buildLocator)},
    },
  }),
}
