import Button from '@jetbrains/ring-ui/components/button/button'
import * as React from 'react'
import {createPortal} from 'react-dom'

import {useSetUserProperty} from '../../../hooks/useSetUserProperty'
import {useBooleanUserProperty, useUserProperty} from '../../../hooks/useUserProperty'
import {UserProperties} from '../../../utils/userProperties'
import version from '../../../utils/version'
import HeaderBanner from '../../App/Header/HeaderBanner/HeaderBanner'
import HeaderBannerFeedback from '../../App/Header/HeaderBanner/HeaderBanner.feedback'

import {useSakuraReleaseBanner} from './SakuraRelease.hooks'
import {SakuraReleasePopup} from './SakuraRelease.popup'

import styles from './SakuraRelease.css'

const feedbackURL = `https://teamcity-support.jetbrains.com/hc/en-us/requests/new?ticket_form_id=360001686659&tcv=${version.full}`

export type SakuraReleaseBannerProps = {
  href: string
}

export function BannerContent({onShow, href}: {onShow: () => void; href: string}) {
  return (
    <div className={styles.content}>
      <span>{'The Sakura UI is here: a fresh look, improved usability, and new features'}</span>
      <div className={styles.controls}>
        <Button primary className={styles.more} onClick={onShow}>
          {'Read more'}
        </Button>
        <Button className={styles.switch} href={href}>
          {'Switch now'}
        </Button>
      </div>
    </div>
  )
}

const SakuraReleaseBanner = React.memo(({href}: SakuraReleaseBannerProps) => {
  const portal = document.getElementById('sakura-release-banner-portal')
  const [show, setShowMore] = React.useState(false)
  const onShow = React.useCallback(() => setShowMore(true), [])
  const onHide = React.useCallback(() => setShowMore(false), [])

  const hasSeenBannerBefore = useBooleanUserProperty(UserProperties.HAS_SEEN_SAKURA_RELEASE_POPUP)
  const hasSeenSakuraUIAfterRelease = useUserProperty(UserProperties.LAST_SEEN_SAKURA_UI_VERSION)
  const showReleaseBanner = useSakuraReleaseBanner()

  const setUserProperty = useSetUserProperty()
  React.useEffect(() => {
    if (!hasSeenBannerBefore && !hasSeenSakuraUIAfterRelease) {
      onShow()
      setUserProperty(UserProperties.HAS_SEEN_SAKURA_RELEASE_POPUP, 'true')
    }
  }, [setUserProperty, hasSeenBannerBefore, hasSeenSakuraUIAfterRelease, onShow])

  const node = (
    <React.Suspense fallback={<div data-suspense-fallback className={styles.placeholder} />}>
      <HeaderBanner
        content={<BannerContent onShow={onShow} href={href} />}
        actions={<HeaderBannerFeedback href={feedbackURL} />}
      />
      <SakuraReleasePopup show={show} onCloseAttempt={onHide} href={href} />
    </React.Suspense>
  )

  return portal && showReleaseBanner ? createPortal(node, portal) : null
})
SakuraReleaseBanner.displayName = 'ClassicSakuraReleaseBanner'
export default SakuraReleaseBanner
