import {graphql} from 'react-relay'
import type {ConcreteRequest} from 'relay-runtime'

import {getHistoryLocator} from '../../../../../rest/locators'
import type {Branch} from '../../../../../services/rest'
import type {BuildTypeId} from '../../../../../types'

export const buildTypeHistoryQuery = graphql`
  query BuildTypeHistoryQuery($locator: String!) {
    builds(locator: $locator) {
      count
    }
  }
` as ConcreteRequest

export type BuildTypeHistoryQueryParams = {
  buildTypeId: BuildTypeId
  branch?: Branch | null
  withRunningAndQueued?: boolean | null
}
export const getBuildTypeHistoryQueryVariables = ({
  buildTypeId,
  branch,
  withRunningAndQueued,
}: BuildTypeHistoryQueryParams) => ({
  locator: `${getHistoryLocator({
    buildTypeId,
    branch,
    buildState: withRunningAndQueued === true ? null : 'finished',
  })},count:1`,
})
