import {compare} from 'natural-orderby'

import {mergeIfDifferent} from '../../../reducers/utils'
import type {NormalizedCloudImage, AgentPoolId} from '../../../types'
import type {KeyValue} from '../../../utils/object'
import {keyValue, objectKeys} from '../../../utils/object'
import stableSort from '../../../utils/stableSort'

import {hiddenCloudTypes} from './AgentsPages.settings'
import type {AgentsTreeExpandState} from './AgentsPages.types'
import type {
  AgentBranchId,
  AgentsTreeRootNodeChild,
  AgentsTreeNode,
  AgentSelectionId,
  AgentsTreeAgentNode,
} from './AgentsSidebar/AgentsSidebar.types'
import {AgentTreeItemType} from './AgentsSidebar/AgentsSidebar.types'

export const mergeExpandState = (
  state1: AgentsTreeExpandState,
  state2: AgentsTreeExpandState,
): AgentsTreeExpandState =>
  objectKeys(state2).reduce(
    (result, key) => ({
      ...result,
      ...keyValue(
        key,
        mergeIfDifferent<KeyValue<AgentBranchId, boolean>>(result[key] ?? {}, state2[key]),
      ),
    }),
    {...state1},
  )

export const SORT_ALFABETICALLY = 'SORT_ALFABETICALLY'
export const SORT_ENABLED_FIRST = 'SORT_ENABLED_FIRST'
export const SORT_RUNNING_FIRST = 'SORT_RUNNING_FIRST'

const typeScore = {
  [AgentTreeItemType.POOL]: -1,
  [AgentTreeItemType.AGENT_TYPE]: 0,
  [AgentTreeItemType.AGENT]: 1,
}

const naturalCompare = compare()

function compareChildren<T extends AgentsTreeNode>(
  child1: T,
  child2: T,
  type: string | undefined,
): number {
  if (child1.type === AgentTreeItemType.AGENT && child2.type === AgentTreeItemType.AGENT) {
    if (type === SORT_ENABLED_FIRST && child1.enabled !== child2.enabled) {
      return !child1.enabled && child2.enabled ? 1 : -1
    }

    if (type === SORT_RUNNING_FIRST && child1.buildId !== child2.buildId) {
      return child1.buildId === undefined && child2.buildId !== undefined ? 1 : -1
    }
  }

  return naturalCompare(child1.title, child2.title)
}

export const sortDiverseChildren = <T extends AgentsTreeRootNodeChild>(
  children: ReadonlyArray<T>,
): ReadonlyArray<T> =>
  stableSort(children, ({type: type1, title: title1}, {type: type2, title: title2}): number => {
    if (type1 === type2) {
      return naturalCompare(title1, title2)
    }

    return typeScore[type1] - typeScore[type2]
  })

export const sortSameChildren = <T extends AgentsTreeNode>(
  children: ReadonlyArray<T>,
  type: string | undefined = SORT_ALFABETICALLY,
): ReadonlyArray<T> =>
  stableSort(children, (child1: T, child2: T): number => compareChildren<T>(child1, child2, type))

export const sortUnathorizedChildren = (children: AgentsTreeAgentNode[]) =>
  stableSort(children, (child1: AgentsTreeAgentNode, child2: AgentsTreeAgentNode): number =>
    child1.connected === child2.connected
      ? naturalCompare(child1.title, child2.title)
      : child1.connected
      ? -1
      : 1,
  )

export const isCloudImageManageable = (
  cloudImage: NormalizedCloudImage | null | undefined,
): boolean => {
  if (hiddenCloudTypes == null || hiddenCloudTypes.length === 0 || cloudImage?.profile == null) {
    return true
  }
  return !hiddenCloudTypes.some(i => cloudImage.profile?.id.startsWith(i))
}

export function isAgentPoolId(id: AgentPoolId | AgentSelectionId | undefined): id is AgentPoolId {
  return id !== undefined && id !== 'unauthorized-agents' && id !== 'detached-agents'
}
