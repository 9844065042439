export const keyBy = <T>(
  items: readonly T[],
  getKey: keyof T | ((item: T) => string),
): Record<string, T> =>
  items.reduce(
    (acc, item) => {
      const key = typeof getKey === 'function' ? getKey(item) : String(item[getKey])
      acc[key] = item
      return acc
    },
    {} as Record<string, T>,
  )
