/**
 * @generated SignedSource<<77dd9848a5344c7e728d9cffaed9059c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useMyIdFragment$data = {
  readonly rawId: number | null;
  readonly " $fragmentType": "useMyIdFragment";
};
export type useMyIdFragment$key = {
  readonly " $data"?: useMyIdFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useMyIdFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useMyIdFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rawId",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "10c2684f07614938a17bc478d113dbcd";

export default node;
