import type {EntryPoint, EntryPointComponent} from 'react-relay'

import {makeResource} from '../../../../utils/makeResource'
import type {AgentsPagesQueries} from '../AgentsPages.queries'
import {agentsPagesQueries} from '../AgentsPages.queries'

export const AgentPageEntryPoint: EntryPoint<EntryPointComponent<AgentsPagesQueries, {}>> = {
  root: makeResource(
    'AgentPage',
    () =>
      import(/* webpackChunkName: "AgentPage", webpackPrefetch: true */ './AgentPage.container'),
  ),
  getPreloadProps: () => ({queries: agentsPagesQueries}),
}
