import type {EntryPoint} from 'react-relay'

import type {ProjectId} from '../../../types'
import {makeResource} from '../../../utils/makeResource'

import type {CleanupProjectPageOuterContainerComponent} from './CleanupProjectPage.container'
import {getUseIsReadonlyQueryVariables, UseIsReadonlyQuery} from './CleanupProjectPage.queries'

export const CleanupProjectPageEntryPoint: EntryPoint<
  CleanupProjectPageOuterContainerComponent,
  ProjectId
> = {
  root: makeResource(
    'CleanupProjectPage',
    () =>
      import(
        /* webpackChunkName: "CleanupProjectPage", webpackPrefetch: true */ './CleanupProjectPage.container'
      ),
  ),
  getPreloadProps: projectId => ({
    queries: {
      readOnlyProjects: {
        parameters: UseIsReadonlyQuery,
        variables: getUseIsReadonlyQueryVariables(projectId),
        options: {networkCacheConfig: {metadata: {essential: true}}},
      },
    },
  }),
}
