import {graphql} from 'react-relay'
import type {ConcreteRequest} from 'relay-runtime'

export const pipelineQuery = graphql`
  query PipelineRunPagePipelineQuery($locator: String!) {
    project(projectLocator: $locator) {
      ...PipelineRunHeaderFragment
    }
  }
` as ConcreteRequest
