import compose from 'lodash/flowRight'
import {useEffect} from 'react'
import {connect} from 'react-redux'

import TagsList from '../components/common/TagsList/TagsList'
import withHook from '../hocs/withHook'
import type {State} from '../reducers/types'
import {getBuildTypeTagsArg} from '../rest/buildTypeTags'
import {getBuildType, getIsSakuraUI} from '../selectors'
import {restApi} from '../services/rest'
import type {BuildTypeId, BuildTypeInternalId} from '../types'
import {emptyArray} from '../utils/empty'
import {subscribeOnBuildTypeEvents} from '../utils/subscriber'
import {BUILD_TAGS_CHANGED} from '../utils/subscriptionEvents'

type OwnProps = {
  buildTypeId: BuildTypeId
  selected?: string | null | undefined
  onSelect?: (arg0: string | null | undefined) => unknown
  onReset?: () => unknown
}
type StateProps = {
  internalId: BuildTypeInternalId | null | undefined
  isSakuraUI: boolean | null | undefined
}
type Props = OwnProps & StateProps

const mapStateToProps = (state: State, {buildTypeId}: OwnProps): StateProps => ({
  internalId: getBuildType(state, buildTypeId)?.internalId,
  isSakuraUI: getIsSakuraUI(state),
})

export default compose(
  connect(mapStateToProps),
  withHook(({buildTypeId, internalId, selected, onReset}: Props) => {
    const {tags, ready, refetch} = restApi.endpoints.getBuildTypeBuildTags.useQuery(
      getBuildTypeTagsArg(buildTypeId),
      {selectFromResult: ({data}) => ({tags: data ?? emptyArray, ready: data != null})},
    )

    useEffect(() => {
      if (onReset != null && selected != null && ready && !tags.includes(selected)) {
        onReset()
      }
    }, [onReset, selected, ready, tags])

    useEffect(
      () =>
        internalId != null
          ? subscribeOnBuildTypeEvents(internalId, [BUILD_TAGS_CHANGED], refetch)
          : undefined,
      [internalId, refetch],
    )

    return {tags}
  }),
)(TagsList)
