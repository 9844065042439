import {connect} from 'react-redux'

import type {State} from '../../../reducers/types'
import {getBuild, getHasArtifacts} from '../../../selectors'
import type {BuildId, BuildTypeId} from '../../../types'
import {resolveWebEntityLink} from '../../../utils/entityLinks'
import {objectToQuery} from '../../../utils/queryParams'

type OwnProps = {
  buildId: BuildId
  path?: string
  level?: number
  timeStamp?: number | null | undefined
  labelledBy?: string
  showToggleHidden?: boolean
  showDownloadLink?: boolean
  showStorageInfo?: boolean
  canSelectDirs?: boolean | null | undefined
  autoWidth?: boolean
  expandedNodes?: ReadonlyArray<string> | null | undefined
  onSelect?: ((path: string, type: string) => unknown) | null | undefined
  onExpand?: ((path: string, expanded: boolean | null | undefined) => unknown) | null | undefined
  urlSync?: boolean
}
type StateProps = {
  level: number
  buildUrl: string | null | undefined
  buildType: BuildTypeId | null | undefined
  hasArtifacts: boolean | null | undefined
}
export type Props = OwnProps & StateProps

const mapStateToProps = (state: State, {buildId, level = 0}: OwnProps): StateProps => {
  const build = getBuild(state, buildId)
  const hasArtifacts = getHasArtifacts(state, buildId)
  return {
    level,
    buildUrl: resolveWebEntityLink(build),
    buildType: build?.buildType,
    hasArtifacts,
  }
}

export const getArtifactsHref = (
  buildUrl?: string | null,
  showAll?: boolean | null,
): string | undefined =>
  buildUrl != null
    ? `${buildUrl}&${objectToQuery({
        tab: 'artifacts',
        showAll: showAll === true ? 'true' : null,
      })}`
    : undefined
export default connect<StateProps, {}, OwnProps, State>(mapStateToProps)
