import {combineReducers} from 'redux'

import {feedbackForm} from './components/common/Feedback/Feedback.slice'
import {
  collapsedBlocks,
  hoveredJob,
  pipelineDraft,
  pipelineError,
  pipelineForm,
  pipelineYaml,
  suggestions,
} from './EditPipelinePage/slices/EditPipelinePage.slices'

export const pipelines = combineReducers({
  collapsedBlocks: collapsedBlocks.reducer,
  hoveredJob: hoveredJob.reducer,
  pipelineDraft: pipelineDraft.reducer,
  pipelineYamlDraft: pipelineYaml.reducer,
  pipelineError: pipelineError.reducer,
  pipelineForm: pipelineForm.reducer,
  feedbackForm: feedbackForm.reducer,
  suggestions: suggestions.reducer,
})
