import type {ApprovalInfo, User} from '../../../services/rest'
import type {BuildId, Group, InexactCountable} from '../../../types'

export type UserApprovalRuleStatus = {
  user: User
  approved: boolean
}

export type GroupApprovalRuleStatus = {
  group: Group
  requiredApprovalsCount: number
  currentlyApprovedBy: InexactCountable & {
    user: ReadonlyArray<User>
  }
}

export enum BuildApprovalViewMode {
  INLINE = 'inline',
  CLASSIC_UI = 'classic_ui',
  POPUP = 'popup',
}

export type OwnProps = {
  buildId: BuildId
  className?: string
  view?: BuildApprovalViewMode
}

export type StateProps = {
  shouldRender: boolean
}

export type Props = OwnProps & StateProps

export type ViewProps = {
  buildId: BuildId
  approvalInfo: ApprovalInfo | null | undefined
}
