import type {State} from '../../../../reducers/types'
import type {StepTypes} from '../types'
import {defaultValidationError} from '../utils/validators.consts'

import type {FormType} from './slices/EditPipelinePage.slices.types'

export const getPipelineDraftDeleted = (id: string) => (state: State) =>
  state.pipelines.pipelineDraft[id]?.deleted

export const getPipelineOriginalIntegrations = (id: string) => (state: State) =>
  state.pipelines.pipelineDraft[id]?.original?.integrations

export const getOriginalPipelineTriggers = (id: string) => (state: State) =>
  state.pipelines.pipelineDraft[id]?.original?.triggers

export const getOriginalPipelineSettings = (id: string) => (state: State) =>
  state.pipelines.pipelineDraft[id]?.original?.settings

export const getOriginal = (state: State, id: string) => state.pipelines.pipelineDraft[id]?.original

export const getDraft = (state: State, id: string) => state.pipelines.pipelineDraft[id]?.draft

export const getDraftJobs = (state: State, id: string) => getDraft(state, id)?.settings.jobs

export const getJobName = (state: State, pipelineId: string, id: string) =>
  state.pipelines.pipelineDraft[pipelineId]?.renamed?.jobs?.[id] ?? id

export const hasJobError = (jobId: string) => (state: State) =>
  state.pipelines.pipelineError.errors.some(error => error.jobId === jobId)

export const getPipelineErrors = (state: State) => state.pipelines.pipelineError.errors

export const hasPipelineErrors = (state: State) =>
  Boolean(state.pipelines.pipelineError.errors.length)

export const getErrorBySpotId = (spotId: string) => (state: State) =>
  state.pipelines.pipelineError.errors.find(({id}) => id === spotId) || defaultValidationError

export const getErrorJobs = (state: State) => state.pipelines.pipelineError.errors

export const getJobAgent = (jobId: string, id: string) => (state: State) =>
  getDraft(state, id)?.settings?.jobs?.[jobId]?.['runs-on']

export const getJobCheckoutWorkingDirectoriesOnly = (jobId: string, id: string) => (state: State) =>
  getDraft(state, id)?.settings?.jobs?.[jobId]?.['checkout-working-directories-only']

export const isPipelineFormOpened = (state: State, formId: string, formType: FormType) => {
  const {id, type, isOpened} = state.pipelines.pipelineForm

  return id === formId && type === formType && isOpened === true
}

export const getSkippedSuggestions = (state: State, stepId: string) =>
  state.pipelines.suggestions.skippedSuggestions[stepId]

export const isSuggestionSkipped = (state: State, stepId: string, type: string) =>
  getSkippedSuggestions(state, stepId)?.includes(type)

export const isSuccessMessageVisible = (state: State, stepId: string, type: StepTypes) =>
  state.pipelines.suggestions.successMessages[stepId] === type
