import {useCallback} from 'react'

import {useLazyLoading} from '../../../hocs/withLazyLoading'
import {getBuild, getHasArtifacts, getHasArtifactsState} from '../../../selectors'
import type {BuildId} from '../../../types'
import {resolveWebEntityLink} from '../../../utils/entityLinks'
import {Namespace} from '../../../utils/namespace'
import {getArtifactsHref} from '../BuildArtifactsTree/BuildArtifactsTree.container'

import BuildArtifacts from './BuildArtifacts'
import {fetchBuildArtifactsCountQueue} from './BuildArtifacts.actions'
import type {
  OwnProps,
  UseBuildArtifactsOutput,
  UseBuildArtifactsParams,
} from './BuildArtifacts.types'

import {useAppDispatch, useAppSelector} from 'src/hooks/react-redux'

const useBuildArtifacts = ({
  buildId,
  childUsesVisibility,
}: UseBuildArtifactsParams): UseBuildArtifactsOutput => {
  const hasArtifacts = useAppSelector(state => getHasArtifacts(state, buildId))
  const needsDetails = useAppSelector(state => {
    const build = getBuild(state, buildId)
    return (
      build != null &&
      (hasArtifacts == null ||
        (!hasArtifacts && getHasArtifactsState(state, buildId) !== build.state))
    )
  })
  const dispatch = useAppDispatch()

  const fetchDetails = useCallback(
    (id: number) => {
      dispatch(fetchBuildArtifactsCountQueue(id))
    },
    [dispatch],
  )

  const lazyLoadingProps = useLazyLoading<BuildId, HTMLSpanElement>({
    namespace: Namespace.ARTIFACTS,
    childUsesVisibility,
    ignoreFetcher: true,
    id: buildId,
    needsDetails,
    fetchDetails,
    defaultVisible: false,
  })
  return {...lazyLoadingProps, hasArtifacts}
}
export default function BuildArtifactsContainer(props: OwnProps) {
  const {buildId} = props
  const hasArtifacts = useAppSelector(state => getHasArtifacts(state, buildId))
  const href = useAppSelector(state =>
    getArtifactsHref(resolveWebEntityLink(getBuild(state, buildId)), !hasArtifacts),
  )
  const isLoading = href == null || hasArtifacts == null
  const addProps = useBuildArtifacts({
    buildId,
    childUsesVisibility: isLoading,
  })
  return <BuildArtifacts {...props} {...addProps} href={href} isLoading={isLoading} />
}
