import {graphql} from 'react-relay'
import type {ConcreteRequest} from 'relay-runtime'

export const UseIsReadonlyQuery = graphql`
  query CleanupProjectPageUseIsReadOnlyQuery($locator: String!) {
    projectsRest(locator: $locator) {
      ...CleanupProjectPageUseIsReadOnlyFragment
    }
  }
` as ConcreteRequest

export const getUseIsReadonlyQueryVariables = (projectId: string) => ({
  locator: `item(readOnlyUI:true,affectedProject:(id:${projectId})),item(readOnlyUI:true,id:${projectId})`,
})
