import type {EntryPoint} from 'react-relay'
import type {LoaderFunctionArgs} from 'react-router'

import {makeResource} from '../../../../utils/makeResource'
import {pipelineHeadQuery} from '../PipelinesPages.queries'

import type {PipelinePageComponent} from './PipelinePage'

export const PipelinePageEntryPoint: EntryPoint<PipelinePageComponent, LoaderFunctionArgs> = {
  root: makeResource(
    'PipelinePage',
    () => import(/* webpackChunkName: "PipelinePage", webpackPrefetch: true */ './PipelinePage'),
  ),
  getPreloadProps: ({params}) => ({
    queries: {
      pipelineHead: {
        parameters: pipelineHeadQuery,
        variables: {locator: `internalId:${params.id}`},
        options: {networkCacheConfig: {metadata: {essential: true}}},
      },
    },
  }),
}
