import * as React from 'react'

import type {HealthItemOwnProps} from './HealthItem.types'

import styles from './HealthItem.css'

const HealthItem = React.lazy(
  () =>
    import(
      /* webpackChunkName: "HealthItem", webpackPrefetch: true */
      './HealthItem'
    ),
)

function HealthItemLazy(props: HealthItemOwnProps) {
  return (
    <React.Suspense fallback={<div className={styles.fallbackHealthItem} />}>
      <HealthItem {...props} />
    </React.Suspense>
  )
}

export default React.memo(HealthItemLazy)
