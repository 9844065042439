import {stringifyId, toBuildId} from '../../../types'
import type {BuildId} from '../../../types'
import {searchParamsToObject} from '../../../utils/queryParams'
import type {QueryParams} from '../../../utils/queryParams'

import type {
  BuildLogKey,
  BuildLogMessage,
  FullLogTarget,
  KeyBuildLogParams,
  LogFilter,
  LogView,
} from './BuildLog.types'

export const getKeyForBuildLogData = (params: KeyBuildLogParams): BuildLogKey =>
  params.type === 'full' ? params.target : `${params.type}_${stringifyId(params.id)}`
const LOG_URL_PARAMS_DELIMITER = '_'
const LOG_URL_EXPAND_STATE_DELIMITER = '.'
export const INITIAL_NEXT_MESSAGES_COUNT = 1000
export const INITIAL_PREVIOUS_MESSAGES_COUNT = -100
export const EXPAND_MESSAGE_COUNT = 1000
const VERY_LONG_MESSAGE_LENGTH = 10000
const MINIMUM_MULTILINE_MESSAGE_LENGTH = 100
const MAX_DURATION_CHARS_COUNT = 22
const ONE_SECOND = 1000
export const LINKED_MESSAGE_TITLE = 'Show original message'
export const generateLinesStateParam = (
  expandState?: ReadonlyArray<number> | null | undefined,
): null | string =>
  expandState != null && expandState.length > 0
    ? expandState.join(LOG_URL_EXPAND_STATE_DELIMITER)
    : null
export const generateShowLogParam = (
  buildId: BuildId,
  line?: (number | null | undefined) | string,
  expandState?: ReadonlyArray<number> | null | undefined,
  expandAll?: boolean | null | undefined,
): string => {
  const showLog: (string | null)[] = [stringifyId(buildId)]

  if (line != null) {
    showLog.push(line.toString())
  }

  if (expandState != null && expandState.length > 0) {
    if (line == null) {
      showLog.push(null)
    }

    showLog.push(generateLinesStateParam(expandState))
  }

  if (expandAll === true) {
    if (line == null) {
      showLog.push(null)
    }

    if (expandState == null || expandState.length === 0) {
      showLog.push('')
    }

    showLog.push('expandAll')
  }

  return showLog.join(LOG_URL_PARAMS_DELIMITER)
}
export const parseExpandState = (
  expandState: string | null | undefined,
): ReadonlyArray<number> | null | undefined =>
  expandState != null && expandState.length > 0
    ? expandState.split(LOG_URL_EXPAND_STATE_DELIMITER).map(index => Number(index))
    : null
export const parseShowLogParam = (
  showLog: string | null | undefined,
): {
  buildId: BuildId | null | undefined
  focusLine: number | null | undefined
  expandState: ReadonlyArray<number> | null | undefined
  expandAll: boolean
} => {
  const [buildId, focusLine, expandState, expandAll] =
    showLog != null ? showLog.split(LOG_URL_PARAMS_DELIMITER) : []
  return {
    buildId: buildId != null ? toBuildId(buildId) : null,
    focusLine: focusLine != null && focusLine.length > 0 ? Number(focusLine) : null,
    expandState: parseExpandState(expandState),
    expandAll: expandAll === 'expandAll',
  }
}
export const getFullBuildLogLinkParams = ({
  buildId,
  line,
  expandState,
  expandAll,
  target,
  logFilter,
  logView,
}: {
  buildId: BuildId
  line?: (number | null | undefined) | string
  expandState?: ReadonlyArray<number> | null | undefined
  expandAll?: boolean | null | undefined
  target?: FullLogTarget
  logFilter?: LogFilter
  logView?: LogView
}): QueryParams => {
  const params = new URLSearchParams(location.search)

  if (target === 'page') {
    if (line != null) {
      params.set('focusLine', line.toString())
    }

    const linesState = generateLinesStateParam(expandState)

    if (linesState != null) {
      params.set('linesState', linesState)
    }

    if (expandAll != null) {
      if (expandAll) {
        params.set('expandAll', 'true')
      } else {
        params.delete('expandAll')
      }
    }
  } else {
    params.set('showLog', generateShowLogParam(buildId, line, expandState, expandAll))
  }

  if (logFilter != null) {
    params.set('logFilter', logFilter)
  }

  if (logView != null) {
    params.set('logView', logView)
  } else {
    params.delete('logView')
  }

  return searchParamsToObject(params)
}
const getIsNonEmptyMessage = (message: BuildLogMessage): boolean =>
  message.text.replace(/\n/g, '').trim().length > 0
const findFirstNonEmptyMessageIndex = (
  messages: ReadonlyArray<BuildLogMessage>,
  index: number,
  reversed?: boolean,
): number | null => {
  if (messages[index] == null) {
    return null
  }
  if (getIsNonEmptyMessage(messages[index])) {
    return index
  }
  return findFirstNonEmptyMessageIndex(messages, reversed ? index - 1 : index + 1, reversed)
}
const emptyArray: BuildLogMessage[] = []
export const getNonEmptyMessages = (
  messages: ReadonlyArray<BuildLogMessage> | null | undefined,
): ReadonlyArray<BuildLogMessage> =>
  messages == null || messages.length === 0
    ? emptyArray
    : messages.filter(message => message.level !== 0 && getIsNonEmptyMessage(message))
export const getTrimmedMessages = (
  messages: ReadonlyArray<BuildLogMessage> | null | undefined,
): ReadonlyArray<BuildLogMessage> => {
  if (messages == null || messages.length === 0) {
    return emptyArray
  }
  let result = messages.filter(message => message.level !== 0)

  const firstNonEmptyMessageIndex = findFirstNonEmptyMessageIndex(result, 0)
  result = firstNonEmptyMessageIndex != null ? result.slice(firstNonEmptyMessageIndex) : result

  const lastNonEmptyMessageIndex = findFirstNonEmptyMessageIndex(result, result.length - 1, true)
  result = lastNonEmptyMessageIndex != null ? result.slice(0, lastNonEmptyMessageIndex + 1) : result

  return result
}
export const getPrevExistIndex = (
  messages: ReadonlyArray<BuildLogMessage | null | undefined>,
  index: number,
): number => {
  let prevIndex = index - 1

  while (prevIndex > 0) {
    if (messages[prevIndex] != null) {
      return prevIndex
    }

    prevIndex--
  }

  return 0
}
export const getNextExistIndex = (
  messages: ReadonlyArray<BuildLogMessage | null | undefined>,
  index: number,
): number => {
  const lastIndex = messages.length - 1
  let nextIndex = index + 1

  while (nextIndex < lastIndex) {
    if (messages[nextIndex] != null) {
      return nextIndex
    }

    nextIndex++
  }

  return lastIndex
}
export const getFirstExistIndex = (
  messages: ReadonlyArray<BuildLogMessage | null | undefined>,
): number => getNextExistIndex(messages, -1)
export const getLastExistIndex = (
  messages: ReadonlyArray<BuildLogMessage | null | undefined>,
): number => getPrevExistIndex(messages, messages.length)
export const ansiRegexpString = '\\s{0,1}(\\[([;\\d]+)m)'
const ansiRegexp = new RegExp(ansiRegexpString, 'i')
export const urlRegexpString =
  '(\\b(https?|ftp|file):\\/\\/[-A-Z0-9+&@#\\/%?=~_|!:,.;]*[-A-Z0-9+&@#\\/%=~_|])'
const urlRegexp = new RegExp(urlRegexpString, 'i')
// eslint-disable-next-line no-control-regex
const unicodeRegexp = /[^\u0000-\u007F]+/

export function getSearchRegExp(searchQuery: string) {
  return new RegExp(searchQuery.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'ig')
}

const isMessageBroken = (message: BuildLogMessage): boolean => message.id === 0 && message.level > 0

export const showDuration = ({duration, level, containsMessages}: BuildLogMessage): boolean =>
  duration != null
    ? duration > 0 && (level === 1 || duration >= ONE_SECOND)
    : containsMessages === true

export const processMessageText = (text: string) => text.replace(/\t/g, '\u00a0\u00a0').trimEnd()

export const processMessage = (message: BuildLogMessage) => {
  if (isMessageBroken(message)) {
    message.isBroken = true
  } else if (message.processed !== true) {
    message.text = processMessageText(message.text)
    message.containUrl = urlRegexp.test(message.text)
    message.containAnsi = ansiRegexp.test(message.text)
    message.inaccurateSize =
      message.text.length > VERY_LONG_MESSAGE_LENGTH ||
      (message.text.length > MINIMUM_MULTILINE_MESSAGE_LENGTH && unicodeRegexp.test(message.text))
    const lines = message.text.split('\n')
    message.linesLengths = lines.map((line: string, index: number) => {
      let charsCount = line.length + 2 * (message.level - 1)

      if (index === lines.length - 1) {
        if (showDuration(message)) {
          charsCount += 1 + MAX_DURATION_CHARS_COUNT
        }

        if (message.linkedMessageId != null) {
          charsCount += 1 + LINKED_MESSAGE_TITLE.length
        }
      }

      return charsCount
    })
    message.maxLineLength = Math.max(...message.linesLengths)
    message.processed = true
  }
}

export const getMessageExpandableChildren = (
  expandableChildrenMap: Map<number, {parentId: number; children: ReadonlyArray<number>}>,
  id: number,
  children: number[] = [],
) => {
  const mapObj = expandableChildrenMap.get(id)

  if (mapObj != null) {
    children.push(...mapObj.children)
    mapObj.children.forEach(item =>
      getMessageExpandableChildren(expandableChildrenMap, item, children),
    )
  }

  return children
}
