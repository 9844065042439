import type {EntryPoint} from 'react-relay'
import type {LoaderFunctionArgs} from 'react-router'

import {BuildPageTabNamesEnum} from '../../../types'
import type {ThinNestedEntryPointParamsObject} from '../../../types/entryPoints'
import {parseBranch} from '../../../utils/branchNames'
import {makeResource} from '../../../utils/makeResource'
import {queryToObject} from '../../../utils/queryParams'

import {BuildOverviewTabEntryPoint} from './BuildOverviewTab/BuildOverviewTab.entryPoint'
import type {BuildPageComponent, NestedEntryPoints} from './BuildPage.container'
import {getBuildOrPermalinkLocator} from './BuildPage.utils'
import {BuildTestsTabEntryPoint} from './BuildTestsTab/BuildTestsTab.entryPoint'
import {DependenciesTabEntryPoint} from './DependenciesTab/DependenciesTab.entryPoint'

export const BuildPageEntryPoint: EntryPoint<BuildPageComponent, LoaderFunctionArgs> = {
  root: makeResource(
    'BuildPage',
    () =>
      import(/* webpackChunkName: "BuildPage", webpackPrefetch: true */ './BuildPage.container'),
  ),
  getPreloadProps({params, request}) {
    const {buildTypeId, buildId} = params
    const url = new URL(request.url)
    const {buildTab, branch} = queryToObject(url.search)
    const entryPoints: ThinNestedEntryPointParamsObject<NestedEntryPoints> = {}
    const buildLocator = getBuildOrPermalinkLocator(buildId, buildTypeId, parseBranch(branch))
    if (buildTypeId != null && buildId != null) {
      switch (buildTab) {
        case BuildPageTabNamesEnum.DEPENDENCIES:
          entryPoints.dependenciesTab = {
            entryPoint: DependenciesTabEntryPoint,
            entryPointParams: {buildTypeId, buildLocator},
          }
          break
        case BuildPageTabNamesEnum.TESTS:
          entryPoints.testsTab = {
            entryPoint: BuildTestsTabEntryPoint,
            entryPointParams: buildLocator,
          }
          break
        case BuildPageTabNamesEnum.OVERVIEW:
        default:
          entryPoints.overviewTab = {
            entryPoint: BuildOverviewTabEntryPoint,
            entryPointParams: buildLocator,
          }
      }
    }
    return {entryPoints}
  },
}
