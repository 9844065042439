import Button from '@jetbrains/ring-ui/components/button/button'
import confirm from '@jetbrains/ring-ui/components/confirm-service/confirm-service'
import type {ReactElement} from 'react'
import {lazy, useEffect, Suspense, useMemo} from 'react'
import type {PreloadedQuery} from 'react-relay'
import {graphql, usePreloadedQuery} from 'react-relay'

import useMyId from '../../../hooks/useMyId'
import {useSlice} from '../../../hooks/useSlice'
import {restApi} from '../../../services/rest'
import type {UserId} from '../../../types'
import {noop} from '../../../utils/empty'
import {Size} from '../Avatar/Avatar.container'
import {AvatarRelay} from '../Avatar/Avatar.relay'

import type {AvatarEditorQuery} from './__generated__/AvatarEditorQuery.graphql'

import styles from './AvatarEditor.css'

const AvatarEditorDialog = lazy(
  () =>
    import(
      /* webpackChunkName: "AvatarEditorDialog", webpackPrefetch: true */
      './AvatarEditorDialog'
    ),
)

export type AvatarEditorProps = {
  userId: UserId
  relative?: boolean
  readOnly?: boolean
}

type Props = AvatarEditorProps & {
  query: PreloadedQuery<AvatarEditorQuery>
}

type State = {
  blob?: Blob | null
  dataURI?: string | null
}
type PayloadMap = {
  setBlob: Blob | null
  setDataURI: string | null
}

export const avatarEditorQuery = graphql`
  query AvatarEditorQuery($userLocator: String!) {
    user(userLocator: $userLocator) {
      ...AvatarFragment
      avatars {
        urlToSize20
      }
      name
      username
    }
  }
`

function AvatarEditor({userId, relative, readOnly, query}: Props): ReactElement | null {
  const {user} = usePreloadedQuery(avatarEditorQuery, query)
  const myId = useMyId()
  const [{blob, dataURI}, {setBlob, setDataURI}] = useSlice<State, PayloadMap>(
    {
      setBlob: (_, payload) => ({blob: payload, dataURI: null}),
      setDataURI: (state, payload) => ({...state, dataURI: payload}),
    },
    {},
  )
  const objectURL = useMemo(() => blob && URL.createObjectURL(blob), [blob])
  const src = dataURI ?? objectURL
  useEffect(
    () => () => {
      if (objectURL != null) {
        URL.revokeObjectURL(objectURL)
      }
    },
    [objectURL],
  )
  const [deleteAvatar] = restApi.endpoints.deleteAvatar.useMutation()

  return (
    <div className={styles.container}>
      {user && (
        <AvatarRelay
          userKey={user}
          className={styles.avatarWrapper}
          avatarClassName={styles.avatar}
          size={Size.Size40}
          displayPlaceholder={false}
          withOutline={false}
          relative={relative}
        />
      )}
      <label className={styles.fileInputWrapper}>
        <input
          disabled={readOnly}
          type="file"
          accept="image/*"
          id="avatar"
          name="avatar"
          className={styles.fileInput}
          onChange={e => {
            const file = e.currentTarget.files?.[0]
            if (file != null) {
              setBlob(file)
              e.currentTarget.value = ''
            }
          }}
        />
        <span className={styles.fileInputLabel}>{`Upload ${
          user?.avatars != null ? 'new ' : ''
        }image...`}</span>
      </label>
      {user?.avatars != null && (
        <Button
          disabled={readOnly}
          onClick={() =>
            confirm({
              text:
                userId === myId
                  ? 'Delete your avatar?'
                  : `Delete the avatar of ${user.name ?? user.username}?`,
              description: 'This cannot be undone',
              confirmLabel: 'Delete',
            }).then(() => deleteAvatar({userLocator: `id:${userId}`}), noop)
          }
        >
          {'Delete...'}
        </Button>
      )}
      {src != null && (
        <Suspense fallback="">
          <AvatarEditorDialog
            userId={userId}
            src={src}
            onCloseAttempt={() => setBlob(null)}
            onError={() => {
              if (blob != null) {
                const reader = new FileReader()
                reader.onload = () => setDataURI(reader.result as string)
                reader.readAsDataURL(blob)
              }
            }}
          />
        </Suspense>
      )}
    </div>
  )
}

export default AvatarEditor
