import type {EntryPoint} from 'react-relay'
import {getRequest} from 'relay-runtime'

import {makeResource} from '../../../../utils/makeResource'
import {
  getCountsVariables,
  testsPreviewPanelCountsQuery,
} from '../../../packages/Tests/TestsPreviewPanel/TestsPreviewPanel.queries'

import type {BuildOverviewTabOuterContainerComponent} from './BuildOverviewTab.container'

export const BuildOverviewTabEntryPoint: EntryPoint<
  BuildOverviewTabOuterContainerComponent,
  string
> = {
  root: makeResource(
    'BuildOverviewTab',
    () =>
      import(
        /* webpackChunkName: "BuildOverviewTab", webpackPrefetch: true */ './BuildOverviewTab.container'
      ),
  ),
  getPreloadProps: buildLocator => ({
    queries: {
      testCounts: {
        parameters: getRequest(testsPreviewPanelCountsQuery),
        variables: getCountsVariables(buildLocator),
      },
    },
  }),
}
