import type {EntryPoint} from 'react-relay'
import type {LoaderFunctionArgs} from 'react-router'

import {PermissionQuery} from '../../hooks/usePermission'
import {getProjectPermissionsLocator} from '../../rest/permission'
import {Permission, ROOT_PROJECT_ID} from '../../types'
import type {ThinQueryParamsObject, ThinNestedEntryPointParamsObject} from '../../types/entryPoints'
import {makeResource} from '../../utils/makeResource'
import {BuildDetailsContentEntryPoint} from '../common/Builds/BuildDetails/BuildDetailsContent/BuildDetailsContent.entryPoint'

import type {AppNestedEntryPoints, AppOuterContainerComponent, AppQueries} from './App.container'
import {appContainerQuery} from './App.queries'
import {isSakuraHeaderUsed} from './Header/Header.utils'

export const AppEntryPoint: EntryPoint<AppOuterContainerComponent, LoaderFunctionArgs> = {
  root: makeResource(
    'App',
    () => import(/* webpackChunkName: "App", webpackPrefetch: true */ './App.container'),
  ),
  getPreloadProps: ({request}) => {
    const queries: ThinQueryParamsObject<AppQueries> = {
      main: {
        parameters: appContainerQuery,
        variables: {},
        options: {networkCacheConfig: {metadata: {essential: true}}},
      },
    }
    if (isSakuraHeaderUsed()) {
      queries.canCreateSubproject = {
        parameters: PermissionQuery,
        variables: {
          locator: getProjectPermissionsLocator(Permission.CREATE_SUB_PROJECT, ROOT_PROJECT_ID),
        },
        options: {networkCacheConfig: {metadata: {essential: true}}},
      }
    }
    const entryPoints: ThinNestedEntryPointParamsObject<AppNestedEntryPoints> = {}
    const url = new URL(request.url)
    const buildId = Number(url.hash)
    if (buildId) {
      entryPoints.buildDetailsContent = {
        entryPoint: BuildDetailsContentEntryPoint,
        entryPointParams: buildId,
      }
    }
    return {queries, entryPoints}
  },
}
