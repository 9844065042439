import {castDraft} from 'immer'

import {createReducer} from '@reduxjs/toolkit'
import * as Redux from 'redux'

import {restApi} from '../../../services/rest'
import {emptyArray, emptyArrayFetchable} from '../../../utils/empty'
import type {KeyValue} from '../../../utils/object'
import stableSort from '../../../utils/stableSort'

import {settings} from './BuildLog.slices'
import type {
  BuildLogKey,
  FetchMessagesParams,
  LastMessageIncludedState,
  MessagesLoadState,
  MessagesState,
  TestAnchorsState,
  BuildLogMessage,
  BuildLogState,
} from './BuildLog.types'
import {processMessage} from './BuildLog.utils'
import {searchStates} from './FullBuildLog/BuildLogHeader/BuildLogSearch/BuildLogSearch.reducers'
import {updateFullLogState} from './FullBuildLog/FullBuildLog.actions'
import {fullLogStates} from './FullBuildLog/FullBuildLog.reducers'

export const buildLogReducers = Redux.combineReducers<BuildLogState>({
  messages: createReducer<MessagesState>({}, builder => {
    builder.addCase(updateFullLogState, (state, action) => {
      const {target, buildId} = action.payload
      // eslint-disable-next-line eqeqeq
      if (buildId === null) {
        // reset messages on close full build log
        state[target] = castDraft(emptyArrayFetchable)
      }
    })

    builder.addMatcher(restApi.endpoints.getBuildLogMessages.matchPending, (state, action) => {
      const {buildLogKey, invalidate} = action.meta.arg.originalArgs
      if (invalidate || state[buildLogKey] == null) {
        state[buildLogKey] = castDraft({...emptyArrayFetchable})
      }
      state[buildLogKey]!.loading = true
    })

    builder.addMatcher(restApi.endpoints.getBuildLogMessages.matchFulfilled, (state, action) => {
      const {buildLogKey, mergeData} = action.meta.arg.originalArgs
      state[buildLogKey] ??= castDraft({...emptyArrayFetchable})
      state[buildLogKey]!.loading = false
      state[buildLogKey]!.ready = true

      const newMessages: ReadonlyArray<BuildLogMessage> | null | undefined =
        action.payload.data.messages

      if (mergeData !== true) {
        state[buildLogKey]!.data =
          newMessages != null
            ? newMessages.filter((message: BuildLogMessage, index: number) => {
                processMessage(message)

                if (
                  index === newMessages.length - 1 &&
                  action.payload.data.lastMessageIncluded === true
                ) {
                  message.isLast = true
                }

                return message.isBroken !== true
              })
            : castDraft(emptyArray)
        return
      }

      // TODO: need to extract this function to util and write unit tests
      if (newMessages == null || newMessages.length === 0) {
        return
      }

      const prevData = state[buildLogKey]!.data

      // TODO: possibly can be optimized to use one loop for filter existing and sorting
      const firstInsertIndex: number = newMessages[0].id
      const lastInsertIndex: number = newMessages[newMessages.length - 1].id
      const firstExistIndex: number | null | undefined = prevData[0]?.id
      const lastExistIndex: number | null | undefined = prevData[prevData.length - 1]?.id
      let holeExists =
        firstExistIndex != null &&
        lastExistIndex != null &&
        firstInsertIndex > firstExistIndex &&
        lastInsertIndex < lastExistIndex
      const exist = new Set()
      const result = newMessages
        .concat(prevData)
        .filter((message: BuildLogMessage, index: number) => {
          processMessage(message)

          if (message.isBroken === true) {
            return false
          }

          if (
            index === newMessages.length - 1 &&
            action.payload.data.lastMessageIncluded === true
          ) {
            message.isLast = true
          }

          if (exist.has(message.id)) {
            if (holeExists && message.id === lastInsertIndex) {
              holeExists = false
            }

            return false
          }

          exist.add(message.id)
          return true
        })

      if (holeExists) {
        newMessages[newMessages.length - 1].nextIsHole = true
      }

      state[buildLogKey]!.data = castDraft(
        stableSort(result, (a: BuildLogMessage, b: BuildLogMessage) => a.id - b.id),
      )
    })

    builder.addMatcher(restApi.endpoints.getBuildLogMessages.matchRejected, (state, action) => {
      if (action.meta.condition) {
        // request was aborted due to condition (another query already running)
        return
      }
      const {buildLogKey} = action.meta.arg.originalArgs
      state[buildLogKey] ??= castDraft({...emptyArrayFetchable})
      state[buildLogKey]!.loading = false
      state[buildLogKey]!.ready = true
    })
  }),
  messagesLoadStates: createReducer<KeyValue<BuildLogKey, MessagesLoadState>>({}, builder => {
    const getKey = (arg: FetchMessagesParams) =>
      arg.options?.target ?? arg.options?.logAnchor?.toString()
    builder.addMatcher(restApi.endpoints.getBuildLogMessages.matchPending, (state, action) => {
      const key = getKey(action.meta.arg.originalArgs)
      if (key == null) {
        return
      }
      state[action.meta.arg.originalArgs.buildLogKey] ??= {}
      state[action.meta.arg.originalArgs.buildLogKey]![key] = {
        loading: true,
        lastLoadedTime:
          action.meta.arg.originalArgs.invalidate === true
            ? null
            : state[action.meta.arg.originalArgs.buildLogKey]![key]?.lastLoadedTime,
      }
    })
    builder.addMatcher(restApi.endpoints.getBuildLogMessages.matchFulfilled, (state, action) => {
      const key = getKey(action.meta.arg.originalArgs)
      if (key == null) {
        return
      }
      state[action.meta.arg.originalArgs.buildLogKey] ??= {}
      state[action.meta.arg.originalArgs.buildLogKey]![key] = {
        loading: false,
        lastLoadedTime: Date.now(),
      }
    })
    builder.addMatcher(restApi.endpoints.getBuildLogMessages.matchRejected, (state, action) => {
      if (action.meta.condition) {
        // request was aborted due to condition (another query already running)
        return
      }
      const key = getKey(action.meta.arg.originalArgs)
      if (key == null) {
        return
      }
      state[action.meta.arg.originalArgs.buildLogKey] ??= {}
      state[action.meta.arg.originalArgs.buildLogKey]![key] = {
        loading: false,
        lastLoadedTime: Date.now(),
      }
    })
  }),
  testAnchors: createReducer<TestAnchorsState>({}, builder => {
    builder.addMatcher(restApi.endpoints.getBuildLogMessages.matchFulfilled, (state, action) => {
      const {testTarget} = action.payload.data
      const current = state[action.meta.arg.originalArgs.buildLogKey]
      if (current != null) {
        Object.assign(current, testTarget)
      } else if (testTarget != null) {
        state[action.meta.arg.originalArgs.buildLogKey] = castDraft(testTarget)
      }
    })
  }),
  lastMessageIncluded: createReducer<LastMessageIncludedState>({}, builder => {
    builder.addMatcher(restApi.endpoints.getBuildLogMessages.matchFulfilled, (state, action) => {
      const {lastMessageIncluded} = action.payload.data
      if (lastMessageIncluded != null) {
        state[action.meta.arg.originalArgs.buildLogKey] = lastMessageIncluded
      }
    })
  }),
  fullLogStates,
  settings: settings.reducer,
  searchStates,
})
