import {produce} from 'immer'

import alertService from '@jetbrains/ring-ui/components/alert-service/alert-service'
import deepEqual from 'fast-deep-equal'
import mapKeys from 'lodash/mapKeys'
import {useCallback, useEffect, useMemo} from 'react'
import {shallowEqual} from 'react-redux'
import {graphql, useFragment, useLazyLoadQuery} from 'react-relay'
import {useSearchParams} from 'react-router-dom'

import {useAppDispatch, useAppSelector} from '../../../../hooks/react-redux'
import {getBranchLocator} from '../../../../rest/locators'
import {defaultBranch} from '../../../../utils/branchNames'
import {copyClipboard} from '../../../../utils/clipboard'
import type {pipelineHeadFragment$key} from '../hooks/__generated__/pipelineHeadFragment.graphql'
import {usePipelineHeadId, usePipelineId} from '../hooks/pipeline'
import {pipelinesApi} from '../services/pipelinesApi'
import type {Job} from '../types'
import {serializeError} from '../utils/error'
import type {GraphNode} from '../utils/graph'
import {createGraph} from '../utils/graph'

import type {EditPipelinePageDependencyFragment$key} from './__generated__/EditPipelinePageDependencyFragment.graphql'
import type {EditPipelinePageLastRunQuery} from './__generated__/EditPipelinePageLastRunQuery.graphql'
import {getDraft, getDraftJobs, getJobName} from './EditPipelinePage.selectors'
import {getDependencyName, parsePipeline, stringifyPipeline} from './EditPipelinePage.utils'
import {pipelineDraft, pipelineYaml} from './slices/EditPipelinePage.slices'
import {MIN_PARALLELISM_COUNT} from './slices/EditPipelinePage.slices.consts'

export const useJobId = (): string | null => {
  const [searchParams] = useSearchParams()
  return searchParams.get('job')
}

export const usePipelineNameOrDraft = () => {
  const id = usePipelineId()
  return useAppSelector(state => getDraft(state, id)?.settings.name) ?? ''
}

export const usePipelineIntegrationsOrDraft = () => {
  const id = usePipelineId()
  return useAppSelector(state => getDraft(state, id)?.integrations)
}

const useExistingJobs = () => {
  const id = usePipelineId()
  const {existing} = pipelinesApi.endpoints.getPipelineById.useQuery(id, {
    selectFromResult: ({data}) => ({existing: data?.settings.jobs}),
  })
  return existing
}

const emptyArray: never[] = []
export const usePipelineJobIdsOrDraft = (): ReadonlyArray<string> => {
  const id = usePipelineId()
  return useAppSelector(state => Object.keys(getDraftJobs(state, id) ?? {}), shallowEqual)
}
export const useNonDeletedPipelineJobIdsOrDraft = (): ReadonlyArray<string> => {
  const jobIds = usePipelineJobIdsOrDraft()
  const id = usePipelineId()
  const deleted = useAppSelector(state => state.pipelines.pipelineDraft[id]?.deleted?.jobs)

  return useMemo(() => jobIds.filter(jobId => !deleted?.includes(jobId)), [jobIds, deleted])
}

export const usePipelineExistingJobIdsSet = (): Set<string> => {
  const existing = useExistingJobs()
  return useMemo(() => new Set(existing != null ? Object.keys(existing) : emptyArray), [existing])
}

export const usePipelineExistingJob = (jobId: string): Job | undefined => {
  const id = usePipelineId()
  const {existing} = pipelinesApi.endpoints.getPipelineById.useQuery(id, {
    selectFromResult: ({data}) => ({existing: data?.settings.jobs?.[jobId]}),
  })
  return existing
}

export const useDoesJobExist = (jobId: string) => {
  const existing = usePipelineExistingJob(jobId)
  return existing != null
}

export const usePipelineGraph = (jobs?: Record<string, Job>): Record<string, GraphNode> => {
  const dependencies = useMemo(() => {
    const result: Record<string, string[]> = {}
    if (jobs != null) {
      Object.entries(jobs).forEach(([id, job]) => {
        result[id] = job.dependencies?.map(getDependencyName) ?? []
      })
    }
    return result
  }, [jobs])
  return useMemo(
    () =>
      createGraph({
        data: Object.keys(dependencies),
        getId: id => id,
        getDependencies: id => dependencies[id],
      }),
    [dependencies],
  )
}

export const usePipelineDraftGraph = (): Record<string, GraphNode> => {
  const id = usePipelineId()
  const jobs = useAppSelector(state => getDraftJobs(state, id))
  return usePipelineGraph(jobs)
}

const GENERATOR_MAX_ITERATIONS = 1000
export const useGenerateFreeJobId = () => {
  const pipelineId = usePipelineId()
  const usedIds = useAppSelector(
    state =>
      Object.keys(getDraftJobs(state, pipelineId) ?? {}).flatMap(key => [
        key,
        getJobName(state, pipelineId, key),
      ]),
    shallowEqual,
  )

  return useCallback((): string => {
    let id
    let index = 0
    while (index <= GENERATOR_MAX_ITERATIONS) {
      index++
      id = `Job ${index}`
      if (!usedIds.includes(id)) {
        return id
      }
    }

    throw new Error(`Error while generating new job id`)
  }, [usedIds])
}

export const useIsJobDeleted = (jobId: string) => {
  const id = usePipelineId()
  return useAppSelector(
    state => state.pipelines.pipelineDraft[id]?.deleted?.jobs?.includes(jobId) ?? false,
  )
}

function useLastRunLocator(pipelineHeadKey: pipelineHeadFragment$key | null) {
  const headId = usePipelineHeadId(pipelineHeadKey)
  const baseLocator = [
    `count:1,buildType:(id:${headId}),state:finished`,
    getBranchLocator(defaultBranch, true),
  ]
    .filter(Boolean)
    .join(',')
  return `item(defaultFilter:false,${baseLocator}),item(${baseLocator})`
}

const dependencyFragment = graphql`
  fragment EditPipelinePageDependencyFragment on Build {
    snapshotDependencies {
      build {
        ...JobTileLastRunFragment
        ...JobStepLastRunFragment
        buildType {
          name
        }
        canceledInfo {
          timestamp
        }
      }
    }
  }
`
const useDependency = (buildKey: EditPipelinePageDependencyFragment$key | null, dep: string) => {
  const build = useFragment(dependencyFragment, buildKey)
  return build?.snapshotDependencies?.build?.find(item => item.buildType?.name === dep) ?? null
}

const lastRunQuery = graphql`
  query EditPipelinePageLastRunQuery($locator: String!) {
    builds(locator: $locator) {
      build {
        ...EditPipelinePageDependencyFragment
      }
    }
  }
`
export function useLastRun(id: string, pipelineHeadKey: pipelineHeadFragment$key | null) {
  const locator = useLastRunLocator(pipelineHeadKey)
  const {builds} = useLazyLoadQuery<EditPipelinePageLastRunQuery>(lastRunQuery, {locator})
  const lastRun = useDependency(builds?.build?.[0] ?? null, id)
  const lastFinishedRun = useDependency(builds?.build?.[1] ?? null, id)
  if (lastRun == null || lastRun.canceledInfo != null) {
    return lastFinishedRun
  }
  return lastRun
}

const useResolvedSettings = () => {
  const pipelineId = usePipelineId()
  const draft = useAppSelector(state => getDraft(state, pipelineId))
  const deleted = useAppSelector(state => state.pipelines.pipelineDraft[pipelineId]?.deleted)
  const renamed = useAppSelector(state => state.pipelines.pipelineDraft[pipelineId]?.renamed)
  const reordered = useAppSelector(state => state.pipelines.pipelineDraft[pipelineId]?.reordered)
  return useMemo(
    () =>
      produce(draft, pipeline => {
        if (pipeline != null) {
          if (pipeline.integrations != null) {
            if (deleted?.integrations != null) {
              pipeline.integrations = pipeline.integrations.filter(
                item => !deleted.integrations?.includes(item.id!),
              )
            }
            if (renamed?.integrations != null) {
              pipeline.integrations.forEach(item => {
                const newId = renamed.integrations?.[item.id!]
                if (newId != null) {
                  item.id = newId
                }
              })
            }
          }
          const {settings} = pipeline
          if (settings.jobs != null) {
            deleted?.jobs?.forEach(id => {
              delete settings.jobs![id]
            })
            if (renamed?.jobs != null) {
              settings.jobs = mapKeys(settings.jobs, (_, id) => renamed.jobs![id] ?? id)
            }
            Object.entries(settings.jobs).forEach(([jobId, job]) => {
              if (deleted?.steps?.[jobId] != null || reordered?.steps?.[jobId] != null) {
                job.steps = job.steps
                  ?.map((_, i) => {
                    const originalIndex = reordered?.steps?.[jobId]?.[i] ?? i
                    return {
                      value: job.steps![originalIndex],
                      isDeleted: deleted?.steps![jobId].includes(originalIndex),
                    }
                  })
                  .filter(({isDeleted}) => !isDeleted)
                  .map(({value}) => value)
              }
              if (renamed?.jobs != null) {
                job.dependencies = job.dependencies?.map(dep => {
                  const id = getDependencyName(dep)
                  const newId = renamed?.jobs?.[id]
                  if (newId == null) {
                    return dep
                  }
                  if (typeof dep === 'string') {
                    return newId
                  }
                  return mapKeys(dep, () => newId)
                })
              }
              if (renamed?.integrations != null) {
                job.integrations = job.integrations?.map(id => renamed.integrations![id] ?? id)
              }
              if (job.parallelism != null && job.parallelism < MIN_PARALLELISM_COUNT) {
                delete job.parallelism
              }
            })
          }
          if (settings.parameters != null) {
            deleted?.parameters?.forEach(name => {
              delete settings.parameters![name]
            })
            if (renamed?.parameters != null) {
              settings.parameters = mapKeys(
                settings.parameters,
                (_, name) => renamed.parameters![name] ?? name,
              )
            }
          }
          if (settings.secrets != null) {
            deleted?.secrets?.forEach(name => {
              delete settings.secrets![name]
            })
            if (renamed?.secrets != null) {
              settings.secrets = mapKeys(
                settings.secrets,
                (_, name) => renamed.secrets![name] ?? name,
              )
            }
          }
        }
      }),
    [deleted, draft, renamed, reordered],
  )
}

export const useEditPipeline = () => {
  const dispatch = useAppDispatch()
  const pipelineId = usePipelineId()
  const updated = useResolvedSettings()
  const original = useAppSelector(state => state.pipelines.pipelineDraft[pipelineId]?.original)

  const [updatePipeline, {isLoading, error}] = pipelinesApi.endpoints.updatePipeline.useMutation()
  useEffect(() => {
    if (error != null) {
      alertService.error(serializeError(error))
    }
  }, [error])

  const handlerSaveButtonClick = async () => {
    if (updated != null) {
      const result = await updatePipeline({id: pipelineId, body: updated})
      if ('data' in result) {
        dispatch(pipelineDraft.actions.set(result.data))
      }
    }
  }
  return {
    updated,
    hasChanges: updated != null && !deepEqual(updated, original),
    isLoading,
    handlerSaveButtonClick,
  }
}
const ALERT_TIMEOUT = 2000

export function useEditPipelineYAML() {
  const dispatch = useAppDispatch()
  const {settings} = useResolvedSettings() ?? {}
  const yaml = (settings && stringifyPipeline(settings)) ?? ''

  const pipelineId = usePipelineId()

  const newYaml = useAppSelector(
    state => state.pipelines.pipelineYamlDraft[pipelineId]?.yaml ?? yaml,
  )
  const isValid = useAppSelector(
    state => !state.pipelines.pipelineYamlDraft[pipelineId]?.diagnostics?.length,
  )
  const hasChanges = yaml !== newYaml

  const pipelineDraftState = useAppSelector(state => getDraft(state, pipelineId))
  const [updatePipeline, {isLoading, error}] = pipelinesApi.endpoints.updatePipeline.useMutation()

  const applyChangesHandler = () => {
    const newSettings = parsePipeline(newYaml)

    if (pipelineDraftState !== undefined) {
      dispatch(
        pipelineDraft.actions.setDraft({
          id: pipelineId,
          draft: {
            ...pipelineDraftState,
            settings: newSettings,
          },
        }),
      )
    }
  }
  const saveChangesHandler = async () => {
    const newSettings = parsePipeline(newYaml)

    if (pipelineDraftState !== undefined) {
      const result = await updatePipeline({
        id: pipelineId,
        body: {
          ...pipelineDraftState,
          settings: newSettings,
        },
      })
      if ('data' in result) {
        dispatch(pipelineDraft.actions.set(result.data))
      }
    }
  }
  const copyToClipboardHandler = () =>
    copyClipboard(newYaml).then(() => alertService.message('Copied to clipboard', ALERT_TIMEOUT))

  const yamlEditorChangeHandler = (value: string) =>
    dispatch(pipelineYaml.actions.setYaml({id: pipelineId, yaml: value}))

  const yamlEditorValidateHandler = (isPipelineValid: boolean) => {
    if (isPipelineValid && hasChanges) {
      applyChangesHandler()
    }
  }

  useEffect(() => {
    if (error != null) {
      alertService.error(serializeError(error))
    }
  }, [error])

  return {
    yaml,
    isValid,
    hasChanges,
    isLoading,
    yamlEditorChangeHandler,
    yamlEditorValidateHandler,
    applyChangesHandler,
    saveChangesHandler,
    copyToClipboardHandler,
  }
}
