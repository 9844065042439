/**
 * @generated SignedSource<<fd275ece6b37647f0c8dc0260b1d3150>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AgentPoolPageAgentPoolProjectsQuery$variables = {
  agentPoolId: string;
};
export type AgentPoolPageAgentPoolProjectsQuery$data = {
  readonly agentPool: {
    readonly __typename: string;
    readonly name: string | null;
    readonly projects: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly agentPools: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly __typename: string;
                readonly id: string;
                readonly name: string | null;
                readonly rawId: string;
              };
            }>;
          } | null;
          readonly ancestorProjects: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly archived: boolean | null;
                readonly id: string;
                readonly name: string | null;
                readonly rawId: string;
              };
            }>;
          } | null;
          readonly archived: boolean | null;
          readonly id: string;
          readonly name: string | null;
          readonly permissions: {
            readonly manageAgentPoolsForProject: boolean | null;
          } | null;
          readonly rawId: string;
        };
      }>;
      readonly excludedCount: number | null;
    } | null;
  } | null;
};
export type AgentPoolPageAgentPoolProjectsQuery = {
  response: AgentPoolPageAgentPoolProjectsQuery$data;
  variables: AgentPoolPageAgentPoolProjectsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "agentPoolId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "agentPoolId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rawId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "archived",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "filter",
      "value": {}
    }
  ],
  "concreteType": "AgentPoolProjectsConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AgentPoolProjectEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Project",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v4/*: any*/),
            (v5/*: any*/),
            (v3/*: any*/),
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectPermissions",
              "kind": "LinkedField",
              "name": "permissions",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "manageAgentPoolsForProject",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectAgentPoolsConnection",
              "kind": "LinkedField",
              "name": "agentPools",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProjectAgentPoolEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v4/*: any*/),
                        (v5/*: any*/),
                        (v3/*: any*/),
                        (v2/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectsConnection",
              "kind": "LinkedField",
              "name": "ancestorProjects",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProjectEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Project",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v4/*: any*/),
                        (v5/*: any*/),
                        (v3/*: any*/),
                        (v6/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "excludedCount",
      "storageKey": null
    }
  ],
  "storageKey": "projects(filter:{})"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AgentPoolPageAgentPoolProjectsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "agentPool",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AgentPoolPageAgentPoolProjectsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "agentPool",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v7/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0031d2bcf95f9fc4f6736ad9b8fcc96f",
    "id": null,
    "metadata": {},
    "name": "AgentPoolPageAgentPoolProjectsQuery",
    "operationKind": "query",
    "text": "query AgentPoolPageAgentPoolProjectsQuery(\n  $agentPoolId: ID!\n) {\n  agentPool(id: $agentPoolId) {\n    __typename\n    name\n    projects(filter: {}) {\n      edges {\n        node {\n          id\n          rawId\n          name\n          archived\n          permissions {\n            manageAgentPoolsForProject\n          }\n          agentPools {\n            edges {\n              node {\n                id\n                rawId\n                name\n                __typename\n              }\n            }\n          }\n          ancestorProjects {\n            edges {\n              node {\n                id\n                rawId\n                name\n                archived\n              }\n            }\n          }\n        }\n      }\n      excludedCount\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "2fa45a4022613f7e3262d3ea08c0212d";

export default node;
