import type {CaseReducer, PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'

export enum ShowFeedbackFormReasons {
  STUCK,
  FOLLOW_UP,
  SAVE_AND_RUN,
}

type FeedbackFormInputType = {
  rating: number
  tags: Array<string>
  comment: string
}

type FeedbackFormVisibilityType = {visible: boolean; reason?: ShowFeedbackFormReasons | null}

type PipelinesFeedbackFormState = {
  visibility: FeedbackFormVisibilityType
  userInput: FeedbackFormInputType
  misc: {
    showCommentField: boolean
  }
}

export const initialState: PipelinesFeedbackFormState = {
  visibility: {
    visible: false,
    reason: null,
  },
  misc: {
    showCommentField: true,
  },
  userInput: {
    rating: 0,
    tags: [],
    comment: '',
  },
}

const setVisibility: CaseReducer<
  PipelinesFeedbackFormState,
  PayloadAction<FeedbackFormVisibilityType>
> = (state, action) => {
  state.visibility.visible = action.payload.visible
  state.visibility.reason = action.payload?.reason ?? null
}

const setRating: CaseReducer<PipelinesFeedbackFormState, PayloadAction<number>> = (
  state,
  action,
) => {
  state.userInput.rating = action.payload
}

const setTags: CaseReducer<PipelinesFeedbackFormState, PayloadAction<Array<string>>> = (
  state,
  action,
) => {
  state.userInput.tags = action.payload
}

const showCommentField: CaseReducer<PipelinesFeedbackFormState, PayloadAction<boolean>> = (
  state,
  action,
) => {
  state.misc.showCommentField = action.payload
}

const setComment: CaseReducer<PipelinesFeedbackFormState, PayloadAction<string>> = (
  state,
  action,
) => {
  state.userInput.comment = action.payload
}

export const feedbackForm = createSlice({
  name: 'feedbackForm',
  initialState,
  reducers: {
    setVisibility,
    setRating,
    setTags,
    setComment,
    showCommentField,
  },
})
