import Button from '@jetbrains/ring-ui/components/button/button'
import Dropdown, {Anchor} from '@jetbrains/ring-ui/components/dropdown/dropdown'
import {H1} from '@jetbrains/ring-ui/components/heading/heading'
import * as React from 'react'
import {useLocation} from 'react-router-dom'

import image from '../../../../../web-webapp/webapp/img/errorPages/404.png'
import image2x from '../../../../../web-webapp/webapp/img/errorPages/404@2x.png'
import EntityLink from '../../../containers/EntityLink'
import {usePrevLocation} from '../../../hooks/routes'
import HTTPError, {HTTPCodesEnum} from '../../../rest/errors/HTTPError'
import NoConnectionError from '../../../rest/errors/NoConnectionError'
import {getAgentsOverviewHref, getFavoriteAgentPoolsHref, isAgentsScreen} from '../../../routes'
import {stringifyId} from '../../../types'
import type {ActiveEntityProps, Id} from '../../../types'
import {BS} from '../../../types/BS_types'
import BuildTypeNamePlain from '../BuildTypeName/BuildTypeName.container'
import Link from '../Link/Link'
import Popup from '../Popup/Popup.lazy'
import RouterLink from '../RouterLink/RouterLink'

import styles from './ErrorPage.css'

export type Props = ActiveEntityProps & {
  error?: (Error | null | undefined) | string
  notFound?: boolean
  referrer?: string | null
  onRetry?: (() => unknown) | null | undefined
  default?: boolean
}
const CHECK_RETRY_INTERVAL = 1000

const reload = () => window.location.reload()

function getEntityIdAndType({
  agentId,
  agentPoolId,
  agentTypeId,
  buildId,
  buildTypeId,
  projectId,
  testId,
}: ActiveEntityProps):
  | {
      id: Id
      type: string
    }
  | null
  | undefined {
  if (agentId) {
    return {
      id: agentId,
      type: 'Agent',
    }
  }

  if (agentPoolId) {
    return {
      id: agentPoolId,
      type: 'AgentPool',
    }
  }

  if (agentTypeId) {
    return {
      id: agentTypeId,
      type: 'CloudImage',
    }
  }

  if (buildId) {
    return {
      id: buildId,
      type: 'Build',
    }
  }

  if (buildTypeId) {
    return {
      id: buildTypeId,
      type: 'Build\u00a0configuration',
    }
  }

  if (projectId) {
    return {
      id: projectId,
      type: 'Project',
    }
  }

  if (testId) {
    return {
      id: testId,
      type: 'Test',
    }
  }

  return null
}

export default function ErrorPage({
  error,
  notFound = false,
  agentId,
  projectId,
  buildTypeId,
  buildId,
  referrer = document.referrer,
  onRetry,
}: Props) {
  const location = useLocation()
  const prevLocation = usePrevLocation()
  const statusCode = error instanceof HTTPError ? error.statusCode : null
  let errorMessage: string = typeof error === 'string' ? error : error?.message ?? ''
  const isNotFound = notFound || statusCode === HTTPCodesEnum.NOT_FOUND
  const isUnauthorized = statusCode === HTTPCodesEnum.UNAUTHORIZED
  const noConnection = error instanceof NoConnectionError
  const entity = getEntityIdAndType({
    agentId,
    buildId,
    buildTypeId,
    projectId,
  })

  if (isNotFound) {
    if (entity != null) {
      errorMessage = `${entity.type} with\u00a0id '${stringifyId(
        entity.id,
      )}' does\u00a0not\u00a0exist`
    } else if (errorMessage === '') {
      errorMessage = 'Page not found'
    }
  }

  const isHtml = /^</.test(errorMessage.trim())
  React.useEffect(() => {
    if (BS != null && onRetry != null && (isUnauthorized || noConnection)) {
      const interval = setInterval(() => {
        if (BS?.ServerLink?.isConnectionAvailable() && onRetry != null) {
          clearInterval(interval)
          onRetry()
        }
      }, CHECK_RETRY_INTERVAL)
      return () => {
        if (interval != null) {
          clearInterval(interval)
        }
      }
    }

    return undefined
  }, [isUnauthorized, noConnection, onRetry])
  return (
    <div className={styles.errorPage}>
      <img alt="404" src={image} srcSet={`${image2x} 2x`} className={styles.image} title="Error" />
      <div className={styles.text}>
        {statusCode != null && (
          <H1 caps className={styles.code}>
            {statusCode}
          </H1>
        )}
        <H1 className={styles.title}>
          {isHtml ? (
            <Dropdown
              hoverMode
              anchor={<Anchor className={styles.details}>{'Network error'}</Anchor>}
            >
              <Popup className={styles.detailsPopup}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: errorMessage,
                  }}
                />
              </Popup>
            </Dropdown>
          ) : error instanceof HTTPError || notFound ? (
            errorMessage
          ) : (
            'Something went wrong'
          )}
        </H1>
        {error instanceof HTTPError || notFound || (
          <>
            <Dropdown hoverMode anchor={<Anchor className={styles.details}>{'Details'}</Anchor>}>
              <Popup className={styles.detailsPopup}>{errorMessage}</Popup>
            </Dropdown>
            <br />
            <br />
          </>
        )}
        <p className={styles.description}>
          {isNotFound && 'Check that\u00a0the\u00a0URL is\u00a0correct.'}
          {!isNotFound && onRetry && (
            <Button className={styles.retry} onClick={onRetry}>
              {'Retry'}
            </Button>
          )}
          {!isNotFound && <Button onClick={reload}>{'Reload page'}</Button>}
          <br />
          <br />
          {isAgentsScreen(location) && (
            <>
              <RouterLink to={getAgentsOverviewHref()}>{'Agents overview'}</RouterLink>
              <br />
              <RouterLink to={getFavoriteAgentPoolsHref()}>{'Favorite agents pools'}</RouterLink>
              <br />
            </>
          )}
          {buildId != null && buildTypeId != null && (
            <>
              <EntityLink buildTypeId={buildTypeId}>
                <BuildTypeNamePlain buildTypeId={buildTypeId} />
              </EntityLink>
              <br />
            </>
          )}
          {prevLocation ? (
            <RouterLink to={`${prevLocation.pathname}${prevLocation.search}`}>
              {'Previous page'}
            </RouterLink>
          ) : (
            referrer && <Link href={referrer}>{'Previous page'}</Link>
          )}
        </p>
      </div>
    </div>
  )
}
