import type {EntryPoint, EntryPointComponent} from 'react-relay'

import {makeResource} from '../../../../utils/makeResource'
import type {AgentsPagesQueries} from '../AgentsPages.queries'
import {agentsPagesQueries} from '../AgentsPages.queries'

export const AgentsPoolsFavoritePageEntryPoint: EntryPoint<
  EntryPointComponent<AgentsPagesQueries, {}>
> = {
  root: makeResource(
    'AgentsPoolsFavoritePage',
    () =>
      import(
        /* webpackChunkName: "AgentsPoolsFavoritePage", webpackPrefetch: true */ './AgentsPoolsFavoritePage'
      ),
  ),
  getPreloadProps: () => ({queries: agentsPagesQueries}),
}
