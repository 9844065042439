import Dropdown from '@jetbrains/ring-ui/components/dropdown/dropdown'
import type {LinkProps} from '@jetbrains/ring-ui/components/link/link'
import {Directions} from '@jetbrains/ring-ui/components/popup/popup.consts'
import classnames from 'classnames'
import type {Ref} from 'react'
import * as React from 'react'
import {useMemo} from 'react'

import EntityLink from '../../../containers/EntityLink'
import {
  getIsSakuraUI,
  makeBuildBranchSelector,
  makeGetClassicUILinkWithBranch,
} from '../../../selectors'
import type {BuildId, BuildTypeId, ProjectId} from '../../../types'
import BreadcrumbsDropdown from '../BreadcrumbsDropdown/BreadcrumbsDropdown'
import Link from '../Link/Link'
import Popup from '../Popup/Popup.lazy'
import Icon from '../ProjectOrBuildTypeIcon/OverviewStatusIcon.container'
import iconStyles from '../ProjectOrBuildTypeIcon/ProjectOrBuildTypeIcon.css'
import {EntityIconType} from '../ProjectOrBuildTypeIcon/ProjectOrBuildTypeIcon.types'

import styles from './BuildPath.css'

import {useAppSelector} from 'src/hooks/react-redux'

type Props = {
  className?: string
  buildId?: BuildId | null | undefined
  buildTypeId?: BuildTypeId
  projectId?: ProjectId
  name: string
  itemKey: string
  setRef?: (el: HTMLElement | null, id: string) => unknown
  showTitle?: boolean
  showPausedInStatus?: boolean
  showInvestigationInStatus?: boolean
  withIcon?: boolean
  withStatusIcon?: boolean | null | undefined
  secondary?: boolean
  withPopup?: boolean
}

function BuildPathLink({
  buildId,
  buildTypeId,
  projectId,
  name,
  setRef,
  showTitle,
  showPausedInStatus,
  showInvestigationInStatus,
  className,
  withIcon,
  withStatusIcon,
  itemKey,
  secondary,
  withPopup,
}: Props) {
  const buildBranchSelector = React.useMemo(makeBuildBranchSelector, [])
  const branch = useAppSelector(state => buildBranchSelector(state, buildId))

  const isSakuraUI = useAppSelector(getIsSakuraUI)

  const getClassicUIHref = React.useMemo(
    () =>
      makeGetClassicUILinkWithBranch(
        {
          buildTypeId,
          projectId,
        },
        branch,
      ),
    [branch, buildTypeId, projectId],
  )
  const classicUIHref = useAppSelector(getClassicUIHref)
  const ref: Ref<HTMLSpanElement> | undefined = React.useMemo(
    () => (setRef != null ? el => setRef(el, itemKey) : undefined),
    [itemKey, setRef],
  )
  const classes = classnames(styles.link, className, {
    [styles.secondary]: secondary,
  })
  const iconProps = useMemo(
    () =>
      projectId != null
        ? ({
            type: EntityIconType.PROJECT,
            id: projectId,
          } as const)
        : buildTypeId != null
        ? ({
            type: EntityIconType.BUILD_TYPE,
            id: buildTypeId,
          } as const)
        : {
            type: undefined,
          },
    [buildTypeId, projectId],
  )
  const commonProps: Pick<LinkProps, 'className' | 'data-test' | 'children'> = {
    className: classes,
    'data-test': 'path-link',
    children: name,
  }
  const link = isSakuraUI ? (
    <EntityLink {...commonProps} buildTypeId={buildTypeId} projectId={projectId} branch={branch} />
  ) : (
    <Link {...commonProps} href={classicUIHref} />
  )

  const icon = useMemo(
    () => (
      <Icon
        {...iconProps}
        withArrow={withPopup}
        showPausedInStatus={showPausedInStatus}
        showInvestigationInStatus={showInvestigationInStatus}
        ignoreStatus={withStatusIcon !== true}
      />
    ),
    [iconProps, withPopup, withStatusIcon, showPausedInStatus, showInvestigationInStatus],
  )
  return (
    <span ref={ref} className={classnames(styles.item, {[iconStyles.arrowWrapper]: withPopup})}>
      {(withIcon === true || withStatusIcon === true) &&
        (withPopup ? (
          <BreadcrumbsDropdown projectId={projectId} buildTypeId={buildTypeId} icon={icon} />
        ) : (
          icon
        ))}
      {showTitle === true ? (
        <Dropdown
          className={styles.pathItemDropDown}
          anchor={link}
          hoverShowTimeOut={300}
          hoverHideTimeOut={300}
          clickMode={false}
          hoverMode
        >
          <Popup left={-1} directions={[Directions.TOP_RIGHT, Directions.BOTTOM_RIGHT]}>
            <div className={styles.hiddenPathPopup}>{link}</div>
          </Popup>
        </Dropdown>
      ) : (
        link
      )}
    </span>
  )
}

export default React.memo(BuildPathLink)
