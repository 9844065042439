import type {CaseReducer, PayloadAction} from '@reduxjs/toolkit'

import type {
  PipelineErrorState,
  PipelineValidationErrorMessage,
} from './EditPipelinePage.slices.types'

export const addError: CaseReducer<
  PipelineErrorState,
  PayloadAction<PipelineValidationErrorMessage>
> = (state, action) => {
  const error = action.payload
  const errorIndex = state.errors.findIndex(({id}) => error.id === id)
  const hasError = errorIndex !== -1

  if (hasError) {
    state.errors[errorIndex] = error
  } else {
    state.errors.push(error)
  }
}

export const removeError: CaseReducer<PipelineErrorState, PayloadAction<string>> = (
  state,
  action,
) => {
  const {payload: errorId} = action

  if (errorId) {
    state.errors = state.errors.filter(({id}) => !id?.startsWith(errorId))
  }
}

export const removeErrorByJobId: CaseReducer<PipelineErrorState, PayloadAction<string>> = (
  state,
  action,
) => {
  const {payload} = action

  if (payload) {
    state.errors = state.errors.filter(({jobId}) => jobId !== payload)
  }
}

export const resetError: CaseReducer<PipelineErrorState> = state => {
  state.errors = []
}
