/**
 * @generated SignedSource<<7522e0c7e41e263168d6bb6573663652>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditPipelinePageDependencyFragment$data = {
  readonly snapshotDependencies: {
    readonly build: ReadonlyArray<{
      readonly buildType: {
        readonly name: string | null;
      } | null;
      readonly canceledInfo: {
        readonly timestamp: string | null;
      } | null;
      readonly " $fragmentSpreads": FragmentRefs<"JobStepLastRunFragment" | "JobTileLastRunFragment">;
    }> | null;
  } | null;
  readonly " $fragmentType": "EditPipelinePageDependencyFragment";
};
export type EditPipelinePageDependencyFragment$key = {
  readonly " $data"?: EditPipelinePageDependencyFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditPipelinePageDependencyFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditPipelinePageDependencyFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Builds",
      "kind": "LinkedField",
      "name": "snapshotDependencies",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Build",
          "kind": "LinkedField",
          "name": "build",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "JobTileLastRunFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "JobStepLastRunFragment"
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "BuildTypeRest",
              "kind": "LinkedField",
              "name": "buildType",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Comment",
              "kind": "LinkedField",
              "name": "canceledInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "timestamp",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Build",
  "abstractKey": null
};

(node as any).hash = "1ff214b6036b88d206bed781f154b675";

export default node;
