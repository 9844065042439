import Tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip'
import classNames from 'classnames'
import plur from 'plur'
import * as React from 'react'
import {useContext} from 'react'

import type {ApprovalInfo} from '../../../services/rest'
import {emptyArray} from '../../../utils/empty'
import Avatar, {Size} from '../Avatar/Avatar.container'

import {BuildApprovalViewContext} from './BuildApproval.context'
import {useGroupedByStatusApprovals} from './BuildApproval.hooks'
import type {GroupApprovalRuleStatus, UserApprovalRuleStatus} from './BuildApproval.types'
import {BuildApprovalViewMode} from './BuildApproval.types'

import styles from './BuildApproval.css'

type ApprovalUser = {
  approvals: ReadonlyArray<UserApprovalRuleStatus>
  withAvatar: boolean
}

function ApprovalUserLine({approvals, withAvatar}: ApprovalUser) {
  const view = useContext(BuildApprovalViewContext)
  const foldNames =
    approvals.length > 1 &&
    withAvatar &&
    approvals?.[0].approved &&
    view !== BuildApprovalViewMode.POPUP

  if (approvals.length === 0) {
    return null
  }

  return (
    <span>
      {approvals.map(({approved, user}, index) => {
        const username = user.name ?? user.username ?? user.id
        const WrapperTag = foldNames ? Tooltip : React.Fragment
        const props = foldNames
          ? {
              popupProps: {top: -1, offset: 4},
              title: username,
            }
          : {}

        return (
          <React.Fragment key={username}>
            <span
              className={classNames(
                styles.user,
                withAvatar && styles.withAvatar,
                foldNames && index !== approvals.length - 1 ? styles.folded : null,
                approved ? styles.approved : styles.awaits,
              )}
            >
              {withAvatar ? (
                <WrapperTag {...props}>
                  <Avatar user={user} size={Size.Size20} withOutline={false} />
                </WrapperTag>
              ) : null}
              {foldNames ? null : <span className={styles.username}>{username}</span>}
              {(foldNames && index === approvals.length - 1) || !foldNames ? (
                <span className={styles.status}>{approved ? 'approved' : 'awaiting'}</span>
              ) : null}
            </span>
            {index !== approvals.length - 1 &&
            !withAvatar &&
            view !== BuildApprovalViewMode.POPUP ? (
              <span>{','}</span>
            ) : null}
          </React.Fragment>
        )
      }, [])}
    </span>
  )
}

function ApprovalUsers({approvals, withAvatar}: ApprovalUser) {
  const groupedApprovals = useGroupedByStatusApprovals(approvals)

  return (
    <>
      <ApprovalUserLine approvals={groupedApprovals.approved} withAvatar={withAvatar} />
      <ApprovalUserLine approvals={groupedApprovals.nonApproved} withAvatar={withAvatar} />
    </>
  )
}

function GroupApproval(props: {groupApproval: GroupApprovalRuleStatus}) {
  const {group, requiredApprovalsCount, currentlyApprovedBy} = props.groupApproval
  const viewMode = useContext(BuildApprovalViewContext)
  const users = React.useMemo(
    () => currentlyApprovedBy.user.map(user => ({user, approved: true})),
    [currentlyApprovedBy.user],
  )

  const approvalsLeft = requiredApprovalsCount - users.length
  const approvalsLeftMessage =
    requiredApprovalsCount - users.length ? (
      <span className={styles.approvalsLeft}>
        {`${approvalsLeft} ${plur('user', approvalsLeft)} awaiting`}
        {users.length && viewMode === BuildApprovalViewMode.INLINE ? <span>{','}</span> : null}
      </span>
    ) : null

  const groupName = `Group "${group.name}"`

  return (
    <span className={styles.group}>
      <span>
        {groupName}
        {approvalsLeftMessage || currentlyApprovedBy.user.length ? <span>{': '}</span> : null}
        {approvalsLeftMessage}
      </span>
      <span>
        {currentlyApprovedBy.user.length > 0 && (
          <ApprovalUsers approvals={users} withAvatar={false} />
        )}
      </span>
    </span>
  )
}

function ApprovalGroups({approvals}: {approvals: ReadonlyArray<GroupApprovalRuleStatus>}) {
  return (
    <>
      {approvals.map(approval => (
        <GroupApproval key={approval.group.key} groupApproval={approval} />
      ))}
    </>
  )
}

type ApprovalProps = {
  approvalInfo: ApprovalInfo
}

export default function BuildApprovalReviewers({approvalInfo}: ApprovalProps) {
  const userApprovals = approvalInfo.userApprovals?.userApproval ?? emptyArray
  const groupApprovals = approvalInfo.groupApprovals?.groupApproval ?? emptyArray
  const view = useContext(BuildApprovalViewContext)

  return (
    <div
      className={classNames({
        [styles.inline]: view === BuildApprovalViewMode.INLINE,
        [styles.popup]: view === BuildApprovalViewMode.POPUP,
      })}
    >
      <ApprovalUsers approvals={userApprovals} withAvatar />
      <ApprovalGroups approvals={groupApprovals} />
    </div>
  )
}
