import type {GetApprovalInfoApiArg} from '../../../services/rest'
import type {BuildId} from '../../../types'
import {stringifyId} from '../../../types'

const userFields = 'id,username,email,name,avatars'
const approvalInfo =
  'status,' +
  'canBeApprovedByCurrentUser,' +
  'configurationValid,' +
  'timeoutTimestamp,' +
  `groupApprovals(groupApproval(requiredApprovalsCount,currentlyApprovedBy(user(${userFields})),group(key,name,users(count)))),` +
  `userApprovals(userApproval(user(${userFields}),approved))`

export const getBuildApprovalArg = (buildId: BuildId): GetApprovalInfoApiArg => ({
  buildLocator: `id:${stringifyId(buildId)}`,
  fields: approvalInfo,
})
