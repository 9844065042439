import {normalize, schema} from 'normalizr'

import type {
  TestOccurrenceInvocations,
  TestOccurrencesCounts,
} from '../components/packages/Tests/Tests.types'
import type {
  Agent,
  AgentPool,
  Build,
  BuildType,
  Change as RestChange,
  Mute,
  TestProblem,
  ProblemOccurrence,
  Project,
  TestOccurrence,
} from '../services/rest'
import {getBuildTypeStatusRequest, getUniqueRevisionId, stringifyId} from '../types'
import type {
  AgentId,
  AgentPoolId,
  AgentPreview,
  BranchWithBuilds,
  BuildId,
  BuildTypeId,
  ChangeId,
  CloudImagesHash,
  CompatibleAgent,
  EntityArchivedSubprojectsIds,
  EntityDescription,
  EntityProjectsIdsList,
  InexactEntityParameters,
  Investigation,
  NormalizedAgentPreview,
  NormalizedAgent,
  NormalizedBranchWithBuilds,
  NormalizedBuild,
  NormalizedChange,
  NormalizedProject,
  NormalizedRevision,
  ProblemOccurrenceId,
  ProjectId,
  RequestOptionsParams,
  SnapshotDependenciesIDList,
  StatusRequest,
  TestOccurrenceId,
  VcsLabel,
  VcsRootId,
  VcsRootInstanceId,
  NormalizedVcsRootInstance,
  VcsRoot,
  WebLinks,
  Revision,
  Template,
  UniqueRevisionId,
  TestId,
} from '../types'
import {stringifyBranch} from '../utils/branchNames'
import {emptyArray, getEmptyHash} from '../utils/empty'
import memoryCache from '../utils/memoryCache'
import type {KeyValue} from '../utils/object'

type Entity = BuildType | Project

const getMainPart = (entity: Entity & WebLinks & InexactEntityParameters) => {
  const {links, parameters, description, projects, ...restProps} = entity
  return restProps
}

const getLinks = (entity: WebLinks): WebLinks => ({
  links: entity.links,
})

const getDescription = (entity: EntityDescription): EntityDescription => ({
  description: entity.description,
})

const getArchivedSubprojectsIds = (entity: EntityArchivedSubprojectsIds): EntityProjectsIdsList =>
  entity.projects?.project ?? []

const getParameters = (entity: InexactEntityParameters): InexactEntityParameters => ({
  parameters: entity.parameters,
})

const buildType = new schema.Entity(
  'buildTypes',
  {},
  {
    processStrategy: getMainPart,
  },
)
const vcsRoots = new schema.Entity('vcsRoots')
const vcsRootInstances = new schema.Entity('vcsRootInstances', {
  'vcs-root': vcsRoots,
})
const changes = new schema.Entity('changes', {
  vcsRootInstance: vcsRootInstances,
})

const modificationsOfChanges = new schema.Entity('modificationsOfChanges', {
  mergedInfo: {
    changes: {
      change: [changes],
    },
  },
})
const revisions = new schema.Entity(
  'revisions',
  {
    'vcs-root-instance': vcsRootInstances,
  },
  {
    idAttribute: ({version, 'vcs-root-instance': vcsRootInstance}: Revision) =>
      getUniqueRevisionId(vcsRootInstance.id, version),
  },
)
const vcsLabels = new schema.Entity(
  'vcsLabels',
  {},
  {
    idAttribute: (_, build) => stringifyId(build?.id),
    processStrategy: labels => (labels ? Object.keys(labels).map(key => labels[key]) : []),
  },
)

const processAgentStrategy = ({environment, ...rest}: Agent) => ({...environment, ...rest})

const build = new schema.Entity(
  'builds',
  {
    buildType,
    versionedSettingsRevision: revisions,
    triggered: {
      buildType,
    },
    changes: {
      change: [changes],
    },
    revisions: {
      revision: [revisions],
    },
    vcsLabels,
  },
  {
    processStrategy: ({agent, ...rest}) => ({
      ...rest,
      ...(agent
        ? {
            agent: processAgentStrategy(agent),
          }
        : {}),
    }),
  },
)
build.define({
  related: {
    builds: {
      build: [build],
    },
  },
  delayedByBuild: build,
  matrixConfiguration: {
    dependencies: {
      dependency: [{build}],
    },
  },
  artifactDependencyChanges: {
    buildChange: [{nextBuild: build}],
  },
  firstBuildWithSameChanges: build,
})

const buildArtifacts = new schema.Entity('buildArtifacts')
const compatibleAgents = new schema.Entity('compatibleAgents')
const agentCloudImage = new schema.Entity(
  'cloudImage',
  {},
  {
    idAttribute: ({agent}) => agent?.typeId,
    processStrategy: ({image: {name, profile}, agent}) => ({
      name,
      agentTypeId: agent?.typeId,
      agentPoolId: agent?.pool?.id,
      agentIds: getEmptyHash(),
      profile: {
        id: profile?.id,
        name: profile?.name,
        projectId: profile?.project?.id,
      },
    }),
  },
)
const agent = new schema.Entity(
  'agents',
  {
    build,
    cloudInstance: agentCloudImage,
  },
  {
    processStrategy: data =>
      processAgentStrategy({...data, cloudInstanceId: data.cloudInstance?.id}),
  },
)

const processAgentPreviewStrategy = ({environment, ...rest}: AgentPreview) => ({
  ...rest,
  osType: environment?.osType,
  ...('build' in rest
    ? {
        build: rest.build?.id,
      }
    : {}),
})

const agentPreview = new schema.Entity(
  'agentPreviews',
  {},
  {
    processStrategy: processAgentPreviewStrategy,
  },
)
const overviewBuildType = new schema.Entity('overviewBuildTypes')
const testProblems = new schema.Entity(
  'testProblems',
  {},
  {
    idAttribute: ({test: {id}}) => id,
  },
)
const testProblemTestOccurrences = new schema.Entity(
  'testProblemTestOccurrences',
  {},
  {
    processStrategy: (entity: TestOccurrence): TestOccurrence => {
      const {mute, test, nextFixed, invocations, metadata, ...restProperties} = entity
      return {
        ...restProperties,
      }
    },
  },
)
const testProblemTestOccurrenceBuilds = new schema.Entity('testProblemTestOccurrenceBuilds', {
  build,
  firstFailed: {
    build,
  },
})
const testProblemsFailingBuildTypesCount = new schema.Entity(
  'testProblemsFailingBuildTypesCount',
  {},
  {
    idAttribute: ({test: {id}}) => id,
    processStrategy: (entity: TestProblem): number | null | undefined =>
      entity.failingBuildTypes?.count,
  },
)
const testProblemsFailingBuildTypes = new schema.Entity(
  'testProblemsFailingBuildTypes',
  {},
  {
    idAttribute: ({test: {id}}) => id,
    processStrategy: (entity: TestProblem): BuildType[] | null | undefined =>
      entity.failingBuildTypes?.buildType,
  },
)
const testProblemsMutes = new schema.Entity(
  'testProblemsMutes',
  {},
  {
    idAttribute: ({test: {id}}) => id,
    processStrategy: (entity: TestProblem): Mute[] | null | undefined => entity.mutes?.mute,
  },
)
const testProblemsInvestigations = new schema.Entity(
  'testProblemsInvestigations',
  {},
  {
    idAttribute: ({test: {id}}) => id,
    processStrategy: (entity: TestProblem): Investigation[] | null | undefined =>
      entity.investigations?.investigation as Investigation[],
  },
)
const problemOccurrences = new schema.Entity('problemOccurrences')
const testOccurrences = new schema.Entity(
  'testOccurrences',
  {},
  {
    processStrategy: (entity: TestOccurrence): TestOccurrence => {
      const {firstFailed, mute, test, ...restTestOccurrencesProps} = entity
      const {mutes, investigations, ...testTestProps} = test ?? {}
      return {...restTestOccurrencesProps, test: testTestProps}
    },
  },
)
const testOccurrencesNewFailure = new schema.Entity(
  'testOccurrencesNewFailure',
  {},
  {
    processStrategy: (entity: TestOccurrence): boolean | null | undefined => entity.newFailure,
  },
)
const testOccurrencesInvocations = new schema.Entity('testOccurrencesInvocations', {
  invocations: {
    testOccurrence: [testOccurrences],
  },
})
const testOccurrencesMetadataCount = new schema.Entity(
  'testOccurrencesMetadataCount',
  {},
  {
    processStrategy: (entity: TestOccurrence): number | null | undefined => entity.metadata?.count,
  },
)
const testOccurrencesFirstFailed = new schema.Entity(
  'testOccurrencesFirstFailed',
  {},
  {
    processStrategy: (entity: TestOccurrence): TestOccurrence | null | undefined =>
      entity.firstFailed,
  },
)
const testOccurrencesNextFixed = new schema.Entity(
  'testOccurrencesNextFixed',
  {},
  {
    processStrategy: (entity: TestOccurrence): TestOccurrence | null | undefined =>
      entity.nextFixed,
  },
)
const testOccurrencesRunOrder = new schema.Entity(
  'testOccurrencesRunOrder',
  {},
  {
    processStrategy: (entity: TestOccurrence): string | null | undefined => entity.runOrder,
  },
)
const testOccurrencesInvestigations = new schema.Entity(
  'testOccurrencesInvestigations',
  {},
  {
    processStrategy: (entity: TestOccurrence) => entity.test?.investigations?.investigation,
  },
)
const testOccurrencesCurrentlyMutes = new schema.Entity(
  'testOccurrencesCurrentlyMutes',
  {},
  {
    processStrategy: (entity: TestOccurrence): ReadonlyArray<Mute> | null | undefined =>
      entity.test?.mutes?.mute,
  },
)
const testOccurrencesInvocationsCounters = new schema.Entity(
  'testOccurrencesInvocationsCounters',
  {},
  {
    processStrategy: (entity: TestOccurrence): TestOccurrencesCounts | null | undefined =>
      entity.invocations?.testCounters,
  },
)
const testOccurrencesMute = new schema.Entity(
  'testOccurrencesMute',
  {},
  {
    processStrategy: (entity: TestOccurrence): Mute | null | undefined => entity.mute,
  },
)
const testOccurrencesBuild = new schema.Entity(
  'testOccurrences',
  {
    build,
  },
  {},
)
const overviewProject = new schema.Entity('overviewProjects', {
  buildTypes: {
    buildType: [overviewBuildType],
  },
})
export type StatusKey = string
export const getStatusKey = (request: StatusRequest | null | undefined): StatusKey =>
  request
    ? [
        request.type,
        request.id,
        request.branch ? stringifyBranch(request.branch) : '',
        request.branch?.default === true ? 'default' : '',
      ]
        .filter(Boolean)
        .join(':')
    : ''
export const stringifyKey = (key: StatusKey): string => key
const branch = new schema.Entity(
  'branches',
  {
    builds: {
      build: [build],
    },
  },
  {
    idAttribute: item => getStatusKey(getBuildTypeStatusRequest(item.buildTypeId, item)),
    mergeStrategy: (branchA, branchB) => ({
      ...branchA,
      builds: {
        build: [...branchA.builds.build, ...branchB.builds.build],
      },
    }),
  },
)
const buildTypeLinks = new schema.Entity(
  'buildTypeLinks',
  {},
  {
    processStrategy: getLinks,
  },
)
const buildTypeDescription = new schema.Entity(
  'buildTypeDescription',
  {},
  {
    processStrategy: getDescription,
  },
)

const buildTypeParameters = new schema.Entity(
  'buildTypeParameters',
  {},
  {
    processStrategy: getParameters,
  },
)
const projectLinks = new schema.Entity(
  'projectLinks',
  {},
  {
    processStrategy: getLinks,
  },
)
const projectDescription = new schema.Entity(
  'projectDescription',
  {},
  {
    processStrategy: getDescription,
  },
)
const projectArchivedSubprojectsIds = new schema.Entity(
  'projectArchivedSubprojectsIds',
  {},
  {
    processStrategy: getArchivedSubprojectsIds,
  },
)
const projectParameters = new schema.Entity(
  'projectParameters',
  {},
  {
    processStrategy: getParameters,
  },
)
const projectTemplates = new schema.Entity(
  'projectTemplates',
  {},
  {
    processStrategy: (project: Project) => project.templates?.buildType ?? emptyArray,
  },
)
const ancestorProject = new schema.Entity('projects', {}, {processStrategy: getMainPart})
const projectWithBuildTypes = new schema.Entity(
  'projects',
  {
    buildTypes: {
      buildType: [buildType],
    },
    ancestorProjects: {
      project: [ancestorProject],
    },
  },
  {
    processStrategy: getMainPart,
  },
)
const projectWithBuildTypesLink = new schema.Entity(
  'projects',
  {
    buildTypes: {
      buildType: [buildTypeLinks],
    },
  },
  {
    processStrategy: getMainPart,
  },
)
const projectWithBuildTypesDescription = new schema.Entity(
  'projects',
  {
    buildTypes: {
      buildType: [buildTypeDescription],
    },
  },
  {
    processStrategy: getMainPart,
  },
)
const projectWithBuildTypesParameters = new schema.Entity(
  'projects',
  {
    buildTypes: {
      buildType: [buildTypeParameters],
    },
  },
  {
    processStrategy: getMainPart,
  },
)
const agentPool = new schema.Entity('agentPools')
const buildsWithBuildTypesLink = new schema.Entity('builds', {
  buildType: buildTypeLinks,
})
const agentWithBuildsAndBuildTypeLinks = new schema.Entity(
  'agents',
  {
    build: buildsWithBuildTypesLink,
  },
  {
    processStrategy: data =>
      processAgentStrategy({...data, cloudInstanceId: data.cloudInstance?.id}),
  },
)
export type Entities = {
  builds: KeyValue<BuildId, NormalizedBuild | null>
  buildsTestOccurrencesCount: KeyValue<BuildId, number | undefined>
  buildArtifacts: KeyValue<BuildId, NormalizedBuild | null>
  compatibleAgents: KeyValue<BuildId, CompatibleAgent | null>
  agents: KeyValue<AgentId, NormalizedAgent | null>
  agent: KeyValue<AgentId, NormalizedAgent | null | undefined>
  agentPreviews: KeyValue<AgentId, NormalizedAgentPreview | null | undefined>
  agentPools?: KeyValue<AgentPoolId, AgentPool>
  cloudImage: CloudImagesHash
  buildTypes: KeyValue<BuildTypeId, BuildType>
  buildTypeParameters: KeyValue<BuildTypeId, InexactEntityParameters>
  buildTypeLinks: KeyValue<BuildTypeId, WebLinks>
  buildTypeDescription: KeyValue<BuildTypeId, EntityDescription>
  projects: KeyValue<ProjectId, NormalizedProject>
  projectDescription: KeyValue<ProjectId, EntityDescription>
  projectArchivedSubprojectsIds?: KeyValue<ProjectId, EntityProjectsIdsList>
  projectParameters: KeyValue<ProjectId, InexactEntityParameters>
  projectLinks: KeyValue<ProjectId, WebLinks>
  projectTemplates: KeyValue<ProjectId, ReadonlyArray<Template>>
  overviewBuildTypes: KeyValue<BuildTypeId, BuildType>
  overviewProjects?: KeyValue<ProjectId, NormalizedProject>
  branches?: KeyValue<StatusKey, NormalizedBranchWithBuilds>
  testOccurrences: KeyValue<TestOccurrenceId, TestOccurrence>
  multirunTestOccurrences: KeyValue<TestOccurrenceId, TestOccurrence>
  testOccurrencesInvocations?: KeyValue<TestOccurrenceId, TestOccurrenceInvocations>
  testOccurrencesFirstFailed: KeyValue<TestOccurrenceId, TestOccurrence | null | undefined>
  testOccurrencesNextFixed: KeyValue<TestOccurrenceId, TestOccurrence | null | undefined>
  testOccurrencesRunOrder: KeyValue<TestOccurrenceId, string | null | undefined>
  testOccurrencesNewFailure: KeyValue<TestOccurrenceId, boolean | null | undefined>
  testOccurrencesMetadataCount: KeyValue<TestOccurrenceId, number | null | undefined>
  testOccurrencesInvestigations: KeyValue<TestOccurrenceId, ReadonlyArray<Investigation>>
  testOccurrencesCurrentlyMutes: KeyValue<TestOccurrenceId, ReadonlyArray<Mute>>
  testOccurrencesMute: KeyValue<TestOccurrenceId, Mute | null | undefined>
  testOccurrencesInvocationsCounters: KeyValue<TestOccurrenceId, TestOccurrencesCounts>
  testProblems: KeyValue<TestId, TestProblem>
  testProblemsMutes: KeyValue<TestId, ReadonlyArray<Mute>>
  testProblemsInvestigations: KeyValue<TestId, ReadonlyArray<Investigation>>
  testProblemTestOccurrences: KeyValue<TestOccurrenceId, TestOccurrence>
  testProblemsFailingBuildTypesCount: KeyValue<TestId, number | null | undefined>
  testProblemsFailingBuildTypes: KeyValue<TestId, BuildType[] | null | undefined>
  problemOccurrences: KeyValue<ProblemOccurrenceId, ProblemOccurrence>
  changes: KeyValue<ChangeId, NormalizedChange>
  modificationsOfChanges: KeyValue<ChangeId, NormalizedChange>
  revisions: KeyValue<UniqueRevisionId, NormalizedRevision>
  vcsLabels: KeyValue<BuildId, ReadonlyArray<VcsLabel>>
  vcsRootInstances: KeyValue<VcsRootInstanceId, NormalizedVcsRootInstance>
  vcsRoots: KeyValue<VcsRootId, VcsRoot>
  snapshotDependencies: SnapshotDependenciesIDList
}
export type Normalized<T> = Readonly<{
  result: T
  entities: Partial<Entities>
}>
export type NormalizedSingleBuild = Normalized<BuildId>
export type NormalizedBuilds = Normalized<ReadonlyArray<BuildId>>
export type NormalizedAgents = Normalized<ReadonlyArray<AgentId>>
export type NormalizedSingleAgent = Normalized<AgentId>
export type NormalizedAgentPools = Normalized<ReadonlyArray<AgentPoolId>>
export type NormalizedSingleBuildType = Normalized<BuildTypeId>
export type NormalizedBuildTypes = Normalized<ReadonlyArray<BuildTypeId>>
export type NormalizedSingleProject = Normalized<ProjectId>
export type NormalizedProjects = Normalized<ReadonlyArray<ProjectId>>
type NormalizedOverview = Normalized<ReadonlyArray<ProjectId>>
type NormalizedBuildTypeLinks = Normalized<BuildTypeId>
type NormalizedBuildTypeParameters = Normalized<BuildTypeId>
export type NormalizedBranches = Normalized<ReadonlyArray<StatusKey>>
export type NormalizedTestOccurrences = Normalized<ReadonlyArray<TestOccurrenceId>>
type NormalizedProblemOccurrences = Normalized<ReadonlyArray<ProblemOccurrenceId>>
export type NormalizedTestProblems = Normalized<ReadonlyArray<TestId>>
export type NormalizedChanges = Normalized<ReadonlyArray<ChangeId>>
export const normalizeBuild: (response: Build) => NormalizedSingleBuild = response =>
  normalize(response, build)
export const normalizeBuilds: (arg0: ReadonlyArray<Build>) => NormalizedBuilds = response =>
  normalize(response, [build])
export const normalizeBuildArtifacts: (arg0: ReadonlyArray<Build>) => NormalizedBuilds = response =>
  normalize(response, [buildArtifacts])
export const normalizeCompatibleAgents: (
  arg0: ReadonlyArray<Build>,
) => NormalizedBuilds = response => normalize(response, [compatibleAgents])
export const normalizeAgents: (arg0: ReadonlyArray<Agent>) => NormalizedAgents = response =>
  normalize(response, [agent])
export const normalizeAgentPreviews: (arg0: ReadonlyArray<Agent>) => NormalizedAgents = response =>
  normalize(response, [agentPreview])
export const normalizeAgent: (arg0: Agent) => NormalizedSingleAgent = response =>
  normalize(response, agent)
export const normalizeAgentPools: (
  arg0: ReadonlyArray<AgentPool>,
) => NormalizedAgentPools = response => normalize(response, [agentPool])
export const normalizeBuildType: (response: BuildType) => NormalizedSingleBuildType = response =>
  normalize(response, buildType)
export const normalizeBuildTypes: (
  arg0: ReadonlyArray<BuildType>,
) => NormalizedBuildTypes = response => normalize(response, [buildType])
export const normalizeBuildTypeLinks: (
  response: BuildType,
) => NormalizedBuildTypeLinks = response => normalize(response, buildTypeLinks)
export const normalizeBuildTypeDescription: (
  response: BuildType,
) => NormalizedBuildTypeLinks = response => normalize(response, buildTypeDescription)
export const normalizeBuildTypeParameters: (
  response: BuildType,
) => NormalizedBuildTypeParameters = response => normalize(response, buildTypeParameters)

const normalizeProjects: (arg0: ReadonlyArray<Project>) => NormalizedProjects = response =>
  normalize(response, [projectWithBuildTypes])

export const normalizeBuildTypeLinksFromBuilds: (
  arg0: ReadonlyArray<Build>,
) => NormalizedBuilds = response => normalize(response, [buildsWithBuildTypesLink])
export const normalizeBuildTypeLinksFromAgentsAndBuilds: (
  arg0: ReadonlyArray<Agent>,
) => NormalizedAgents = response => normalize(response, [agentWithBuildsAndBuildTypeLinks])

const normalizeBuildTypeLinksFromProjects: (
  arg0: ReadonlyArray<Project>,
) => NormalizedProjects = response => normalize(response, [projectWithBuildTypesLink])

const normalizeBuildTypeDescriptionFromProjects: (
  arg0: ReadonlyArray<Project>,
) => NormalizedProjects = response => normalize(response, [projectWithBuildTypesDescription])

const normalizeBuildTypeParametersFromProjects: (
  arg0: ReadonlyArray<Project>,
) => NormalizedProjects = response => normalize(response, [projectWithBuildTypesParameters])

const normalizeProjectsArchivedSubprojectsIds: (
  arg0: ReadonlyArray<Project>,
) => NormalizedSingleProject = response => normalize(response, [projectArchivedSubprojectsIds])

const normalizeProjectsLinks: (arg0: ReadonlyArray<Project>) => NormalizedProjects = response =>
  normalize(response, [projectLinks])

const normalizeProjectTemplates = (response: ReadonlyArray<Project>): NormalizedProjects =>
  normalize(response, [projectTemplates])

const normalizeProjectsDescription: (
  arg0: ReadonlyArray<Project>,
) => NormalizedProjects = response => normalize(response, [projectDescription])

const normalizeProjectsParameters: (
  arg0: ReadonlyArray<Project>,
) => NormalizedProjects = response => normalize(response, [projectParameters])

export const normalizeOverview: (arg0: ReadonlyArray<Project>) => NormalizedOverview = response =>
  normalize(response, [overviewProject])
export const normalizeBranches: (
  arg0: ReadonlyArray<BranchWithBuilds>,
) => NormalizedBranches = response => normalize(response, [branch])
export const normalizeTestOccurrences: (
  arg0: ReadonlyArray<TestOccurrence>,
) => NormalizedTestOccurrences = response => normalize(response, [testOccurrences])
export const normalizeTestOccurrencesInvocations: (
  arg0: ReadonlyArray<TestOccurrence>,
) => NormalizedTestOccurrences = response => normalize(response, [testOccurrencesInvocations])
export const normalizeTestOccurrencesNewFailure: (
  arg0: ReadonlyArray<TestOccurrence>,
) => NormalizedTestOccurrences = response => normalize(response, [testOccurrencesNewFailure])
export const normalizeTestOccurrencesMetadataCount: (
  arg0: ReadonlyArray<TestOccurrence>,
) => NormalizedTestOccurrences = response => normalize(response, [testOccurrencesMetadataCount])
export const normalizeTestOccurrencesFirstFailed: (
  arg0: ReadonlyArray<TestOccurrence>,
) => NormalizedTestOccurrences = response => normalize(response, [testOccurrencesFirstFailed])
export const normalizeTestOccurrencesNextFixed: (
  arg0: ReadonlyArray<TestOccurrence>,
) => NormalizedTestOccurrences = response => normalize(response, [testOccurrencesNextFixed])
export const normalizeTestOccurrencesRunOrder: (
  arg0: ReadonlyArray<TestOccurrence>,
) => NormalizedTestOccurrences = response => normalize(response, [testOccurrencesRunOrder])
export const normalizeTestOccurrencesInvestigations: (
  arg0: ReadonlyArray<TestOccurrence>,
) => NormalizedTestOccurrences = response => normalize(response, [testOccurrencesInvestigations])
export const normalizeTestOccurrencesCurrentlyMutes: (
  arg0: ReadonlyArray<TestOccurrence>,
) => NormalizedTestOccurrences = response => normalize(response, [testOccurrencesCurrentlyMutes])
export const normalizeTestOccurrencesInvocationsCounters: (
  arg0: ReadonlyArray<TestOccurrence>,
) => NormalizedTestOccurrences = response =>
  normalize(response, [testOccurrencesInvocationsCounters])
export const normalizeTestOccurrencesMute: (
  arg0: ReadonlyArray<TestOccurrence>,
) => NormalizedTestOccurrences = response => normalize(response, [testOccurrencesMute])
export const normalizeTestOccurrencesBuild: (
  arg0: ReadonlyArray<TestOccurrence>,
) => NormalizedTestOccurrences = response => normalize(response, [testOccurrencesBuild])
export const normalizeProblemOccurrences: (
  arg0: ReadonlyArray<ProblemOccurrence>,
) => NormalizedProblemOccurrences = response => normalize(response, [problemOccurrences])
export const normalizeTestProblems: (
  arg0: ReadonlyArray<TestProblem>,
) => NormalizedTestProblems = response => normalize(response, [testProblems])
export const normalizeTestProblemTestOccurrences: (
  arg0: ReadonlyArray<TestOccurrence>,
) => NormalizedTestOccurrences = response => normalize(response, [testProblemTestOccurrences])
export const normalizeTestProblemTestOccurrenceBuilds: (
  arg0: ReadonlyArray<TestOccurrence>,
) => NormalizedTestOccurrences = response => normalize(response, [testProblemTestOccurrenceBuilds])
export const normalizeTestProblemsFailingBuildTypesCount: (
  arg0: ReadonlyArray<TestProblem>,
) => NormalizedTestProblems = response => normalize(response, [testProblemsFailingBuildTypesCount])
export const normalizeTestProblemsFailingBuildTypes: (
  arg0: ReadonlyArray<TestProblem>,
) => NormalizedTestProblems = response => normalize(response, [testProblemsFailingBuildTypes])
export const normalizeTestProblemsMutes: (
  arg0: ReadonlyArray<TestProblem>,
) => NormalizedTestProblems = response => normalize(response, [testProblemsMutes])
export const normalizeTestProblemsInvestigations: (
  arg0: ReadonlyArray<TestProblem>,
) => NormalizedTestProblems = response => normalize(response, [testProblemsInvestigations])
export const normalizeBuildChanges: (arg0: ReadonlyArray<Build>) => NormalizedBuilds = response =>
  normalize(response, [build])
export const normalizeChanges: (arg0: ReadonlyArray<RestChange>) => NormalizedChanges = response =>
  normalize(response, [changes])
export const normalizeModificationsOfChange: (
  arg0: RestChange,
) => Normalized<ChangeId> = response => normalize(response, modificationsOfChanges)
export const normalizeProjectsData = (
  data: ReadonlyArray<Project>,
  options: RequestOptionsParams,
  resetCache = true,
): NormalizedProjects => {
  if (resetCache) {
    memoryCache.reset('fullPath')
  }
  const project = normalizeProjects(data)
  let entities = {...project.entities}

  if (options.withDescription === true) {
    entities = {
      ...entities,
      ...normalizeProjectsDescription(data).entities,
      ...normalizeBuildTypeDescriptionFromProjects(data).entities,
    }
  }

  if (options.withLinks === true) {
    entities = {...entities, ...normalizeProjectsLinks(data).entities}
  }

  if (options.withParameters === true) {
    entities = {...entities, ...normalizeProjectsParameters(data).entities}
  }

  if (options.withTemplates === true) {
    entities = {...entities, ...normalizeProjectTemplates(data).entities}
  }

  if (options.withBuildTypes === true && options.withLinks === true) {
    entities = {
      ...entities,
      ...normalizeProjectsLinks(data).entities,
      ...normalizeBuildTypeLinksFromProjects(data).entities,
    }
  }

  if (options.withBuildTypes === true && options.withParameters === true) {
    entities = {...entities, ...normalizeBuildTypeParametersFromProjects(data).entities}
  }

  if (options.withArchivedSubprojectsIds === true) {
    entities = {...entities, ...normalizeProjectsArchivedSubprojectsIds(data).entities}
  }

  return {
    result: project.result,
    entities,
  }
}
