/**
 * @generated SignedSource<<9d69d6d9c327b64b3d390518fafc79ea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AgentsPagesAgentTypesQuery$variables = {};
export type AgentsPagesAgentTypesQuery$data = {
  readonly agentPoolsRest: {
    readonly agentPool: ReadonlyArray<{
      readonly agentTypes: {
        readonly agentType: ReadonlyArray<{
          readonly environment: {
            readonly osName: string | null;
            readonly osType: string | null;
          } | null;
          readonly isCloud: boolean | null;
          readonly name: string | null;
          readonly rawId: number | null;
        }> | null;
      } | null;
      readonly id: string;
      readonly name: string | null;
      readonly rawId: number | null;
    }> | null;
  } | null;
};
export type AgentsPagesAgentTypesQuery = {
  response: AgentsPagesAgentTypesQuery$data;
  variables: AgentsPagesAgentTypesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rawId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isCloud",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Environment",
  "kind": "LinkedField",
  "name": "environment",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "osType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "osName",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AgentsPagesAgentTypesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AgentPools",
        "kind": "LinkedField",
        "name": "agentPoolsRest",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AgentPoolRest",
            "kind": "LinkedField",
            "name": "agentPool",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AgentTypesRest",
                "kind": "LinkedField",
                "name": "agentTypes",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AgentTypeRest",
                    "kind": "LinkedField",
                    "name": "agentType",
                    "plural": true,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AgentsPagesAgentTypesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AgentPools",
        "kind": "LinkedField",
        "name": "agentPoolsRest",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AgentPoolRest",
            "kind": "LinkedField",
            "name": "agentPool",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AgentTypesRest",
                "kind": "LinkedField",
                "name": "agentTypes",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AgentTypeRest",
                    "kind": "LinkedField",
                    "name": "agentType",
                    "plural": true,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a76e4db9b60a942cea26242c1bc30090",
    "id": null,
    "metadata": {},
    "name": "AgentsPagesAgentTypesQuery",
    "operationKind": "query",
    "text": "query AgentsPagesAgentTypesQuery {\n  agentPoolsRest {\n    agentPool {\n      id\n      rawId\n      name\n      agentTypes {\n        agentType {\n          rawId\n          name\n          isCloud\n          environment {\n            osType\n            osName\n          }\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "075ff8d32342a338d75f1fa019cd300c";

export default node;
