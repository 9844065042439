import * as React from 'react'

import withHook from '../../../hocs/withHook'
import type {Enhancer} from '../../../types'
import {noop} from '../../../utils/empty'
import createSimpleStream from '../../../utils/simpleStream'
import type {SimpleStream} from '../../../utils/simpleStream'

type Props = {
  isReady: boolean
}
type ContextValue = {
  onReady(): void
  isLoading: boolean
}

export const sequenceLoaderDefaultContext = {onReady: noop, isLoading: false}
export const SequenceLoaderContext: React.Context<ContextValue> = React.createContext<ContextValue>(
  sequenceLoaderDefaultContext,
)
export function useSequenceLoaderReadyHandler(isReady?: boolean): void {
  const {onReady} = React.useContext(SequenceLoaderContext)
  React.useEffect(() => {
    if (isReady) {
      onReady()
    }
  }, [isReady, onReady])
}
export const withSequenceLoaderReadyHandler: Enhancer<any, Props> = withHook((props: Props) => {
  useSequenceLoaderReadyHandler(props.isReady)
}, 'withSequenceLoaderReadyHandler')
const ResetLoadingStateContext: React.Context<SimpleStream<void>> = React.createContext(
  createSimpleStream(),
)
type ResetLoadingStateProviderProps = {
  children: React.ReactNode
}
export function ResetLoadingStateProvider({children}: ResetLoadingStateProviderProps) {
  const resetLoadingState$ = React.useMemo(() => createSimpleStream<void>(), [])
  return (
    <ResetLoadingStateContext.Provider value={resetLoadingState$}>
      {children}
    </ResetLoadingStateContext.Provider>
  )
}
export const useResetLoadingState = (): (() => unknown) =>
  React.useContext(ResetLoadingStateContext).fire
export function useOnResetLoadingState(handler: () => unknown): void {
  const resetLoadingState$ = React.useContext(ResetLoadingStateContext)
  React.useEffect(() => resetLoadingState$.subscribe(handler), [handler, resetLoadingState$])
}
