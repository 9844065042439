import {skipToken} from '@reduxjs/toolkit/query'
import * as React from 'react'

import {getSingleProjectArg} from '../../../../rest/projects'
import {getBuild, getBuildType, getProjectLinks} from '../../../../selectors'
import {restApi} from '../../../../services/rest'
import type {BuildId} from '../../../../types'
import {resolveWebEntityEditLink} from '../../../../utils/entityLinks'
import Link from '../../Link/Link'

import {useArtifactStorage} from './BuildArtifactStorageInfo.hooks'

import styles from './BuildArtifactStorageInfo.css'

import {useAppSelector} from 'src/hooks/react-redux'

type Props = {
  buildId: BuildId
}
export default function BuildArtifactStorageInfo({buildId}: Props) {
  const projectId = useAppSelector(state => {
    const buildTypeId = getBuild(state, buildId)?.buildType
    return getBuildType(state, buildTypeId)?.projectId
  })
  const projectLinksLoaded = useAppSelector(state => getProjectLinks(state, projectId) != null)
  restApi.endpoints.getProjectNormalized.useQuerySubscription(
    projectId != null && !projectLinksLoaded
      ? getSingleProjectArg(projectId, {withLinks: true})
      : skipToken,
  )
  const editHref = useAppSelector(state => {
    const links = getProjectLinks(state, projectId)
    return resolveWebEntityEditLink(links)
  })
  const storage = useArtifactStorage(buildId)
  const {name, type} = storage

  if (name.length === 0 && type.length === 0) {
    return null
  }

  const formattedName = name.length > 0 ? `${name} (${type})` : type
  return (
    <div className={styles.info}>
      {'Some artifacts of this build were published using '}
      {editHref != null ? (
        <Link href={`${editHref}&tab=artifactsStorage`}>{formattedName}</Link>
      ) : (
        formattedName
      )}
    </div>
  )
}
