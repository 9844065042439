import * as React from 'react'

import type {HOC} from '../types'

import withHook from './withHook'

type MaybeCleanUpFn = (() => unknown) | void
type EffectHOC = {
  <P, T1>(getArg0: (props: P) => T1, effect: (arg0: T1) => MaybeCleanUpFn): HOC<P, P>
  <P, T1, T2>(
    getArg0: (props: P) => T1,
    getArg1: (props: P) => T2,
    effect: (arg0: T1, arg1: T2) => MaybeCleanUpFn,
  ): HOC<P, P>
  <P, T1, T2, T3>(
    getArg0: (props: P) => T1,
    getArg1: (props: P) => T2,
    getArg2: (props: P) => T3,
    effect: (arg0: T1, arg1: T2, arg2: T3) => MaybeCleanUpFn,
  ): HOC<P, P>
  <P, T1, T2, T3, T4>(
    getArg0: (props: P) => T1,
    getArg1: (props: P) => T2,
    getArg2: (props: P) => T3,
    getArg3: (props: P) => T4,
    effect: (arg0: T1, arg1: T2, arg2: T3, arg3: T4) => MaybeCleanUpFn,
  ): HOC<P, P>
  <P, T1, T2, T3, T4, T5>(
    getArg0: (props: P) => T1,
    getArg1: (props: P) => T2,
    getArg2: (props: P) => T3,
    getArg3: (props: P) => T4,
    getArg4: (props: P) => T5,
    effect: (arg0: T1, arg1: T2, arg2: T3, arg3: T4, arg4: T5) => MaybeCleanUpFn,
  ): HOC<P, P>
  <P, T1, T2, T3, T4, T5, T6>(
    getArg0: (props: P) => T1,
    getArg1: (props: P) => T2,
    getArg2: (props: P) => T3,
    getArg3: (props: P) => T4,
    getArg4: (props: P) => T5,
    getArg5: (props: P) => T6,
    effect: (arg0: T1, arg1: T2, arg2: T3, arg3: T4, arg4: T5, arg5: T6) => MaybeCleanUpFn,
  ): HOC<P, P>
  <P, T1, T2, T3, T4, T5, T6, T7>(
    getArg0: (props: P) => T1,
    getArg1: (props: P) => T2,
    getArg2: (props: P) => T3,
    getArg3: (props: P) => T4,
    getArg4: (props: P) => T5,
    getArg5: (props: P) => T6,
    getArg6: (props: P) => T7,
    effect: (
      arg0: T1,
      arg1: T2,
      arg2: T3,
      arg3: T4,
      arg4: T5,
      arg5: T6,
      arg6: T7,
    ) => MaybeCleanUpFn,
  ): HOC<P, P>
  // Add more if needed
  <P, T1, T2, T3, T4, T5, T6, T7, T8>(
    getArg0: (props: P) => T1,
    getArg1: (props: P) => T2,
    getArg2: (props: P) => T3,
    getArg3: (props: P) => T4,
    getArg4: (props: P) => T5,
    getArg5: (props: P) => T6,
    getArg6: (props: P) => T7,
    getArg7: (props: P) => T8,
    effect: (
      arg0: T1,
      arg1: T2,
      arg2: T3,
      arg3: T4,
      arg4: T5,
      arg5: T6,
      arg6: T7,
      arg7: T8,
    ) => MaybeCleanUpFn,
  ): HOC<P, P>
}

/* performs effect whenever some of the input selectors returns a changed value */
const withEffect: EffectHOC = (...args: ReadonlyArray<any>) => {
  const selectors = args.slice(0, -1)
  const effect = args[args.length - 1]
  return withHook(props => {
    const values = selectors.map(selector => selector(props))
    React.useEffect(() => {
      const cleanup = effect(...values)
      return typeof cleanup === 'function' ? cleanup : undefined // eslint-disable-next-line react-hooks/exhaustive-deps
    }, values)
  })
}

export default withEffect
export const useFetchEffect: typeof React.useEffect = (
  create,
  inputs, // eslint-disable-next-line react-hooks/exhaustive-deps
) => React.useEffect(() => (window.GLOBAL_FETCH_DISABLED === true ? undefined : create()), inputs)
