import {graphql} from 'react-relay'
import type {ConcreteRequest} from 'relay-runtime'

import {getArtifactDependencyExistsLocator} from '../../../../rest/builds'
import type {BuildTypeId} from '../../../../types'

import type {DependenciesTabQuery$variables} from './__generated__/DependenciesTabQuery.graphql'
import {getBuildChainLocator, getBuildTypeChainLocator} from './DependenciesTab.utils'

export const dependenciesTabQuery = graphql`
  query DependenciesTabQuery(
    $buildTypeLocator: String!
    $chainBuildsLocator: String!
    $downloadedArtifactBuildsLocator: String!
    $deliveredArtifactBuildsLocator: String!
  ) {
    buildTypes(locator: $buildTypeLocator) {
      count
    }
    chainBuilds: builds(locator: $chainBuildsLocator) {
      count
    }
    downloadedArtifactBuilds: builds(locator: $downloadedArtifactBuildsLocator) {
      count
    }
    deliveredArtifactBuilds: builds(locator: $deliveredArtifactBuildsLocator) {
      count
    }
  }
` as ConcreteRequest

export const getDependenciesTabQueryVariables = (
  buildTypeId: BuildTypeId,
  buildLocator: string,
): DependenciesTabQuery$variables => ({
  buildTypeLocator: `${getBuildTypeChainLocator(buildTypeId)},count:1`,
  chainBuildsLocator: `${getBuildChainLocator(buildLocator)},count:1`,
  downloadedArtifactBuildsLocator: getArtifactDependencyExistsLocator(buildLocator, true),
  deliveredArtifactBuildsLocator: getArtifactDependencyExistsLocator(buildLocator, false),
})
