import LoaderInline from '@jetbrains/ring-ui/components/loader-inline/loader-inline'
import {skipToken} from '@reduxjs/toolkit/query'
import classNames from 'classnames'
import * as React from 'react'
import {shallowEqual} from 'react-redux'

import {useAppSelector} from '../../../hooks/react-redux'
import {useBooleanQueryParamState} from '../../../hooks/routes'
import {getArtifactsArg} from '../../../rest/artifacts'
import {getExtensionEndpointsByKind} from '../../../selectors'
import {buildArtifactsApi} from '../../../services/buildArtifacts'
import {restApi} from '../../../services/rest'
import type {File} from '../../../types'
import {stringifyId} from '../../../types'
import {focusSelfOrChildLink} from '../../../utils/dom'
import {emptyArray} from '../../../utils/empty'
import {useSandbox} from '../../../utils/fastdom'
import {getChildPath, getType, isOnlyFolder} from '../../../utils/fileTree'
import {escapeFilePathForURL} from '../../../utils/url'
import Link from '../Link/Link'

import ArtifactsDownloadAll from './ArtifactsDownloadAll/ArtifactsDownloadAll'
import BuildArtifactStorageInfo from './BuildArtifactStorageInfo/BuildArtifactStorageInfo'
import type {Props} from './BuildArtifactsTree.container'
import connect, {getArtifactsHref} from './BuildArtifactsTree.container'
import {showDownloadAllArtifactsLink} from './BuildArtifactsTree.settings'
import FileTreeNode, {ITEM_SELECTOR, MIN_OFFSET, STEP} from './FileTreeNode/FileTreeNode'

import styles from './BuildArtifactsTree.css'

const TEAMCITY_DIRNAME = '.teamcity'

const isHiddenArtifact = ({name}: File) =>
  window.internalProps['teamcity.hidden.artifacts.use.extended.names']
    ? name.startsWith(TEAMCITY_DIRNAME)
    : name === TEAMCITY_DIRNAME

const isParent = (parent: string, child: string) => child.indexOf(getChildPath(parent, '')) === 0

const getLeftIndent = (level: number) => {
  const indent = 10
  const extraIndent = 5
  const paddingLeft = level ? (level + 2) * indent + extraIndent : 0

  return {paddingLeft}
}

const ConnectedTree = connect(function BuildArtifactsTree({
  buildId,
  buildUrl,
  path = '',
  buildType,
  level,
  labelledBy,
  showToggleHidden,
  showDownloadLink,
  showStorageInfo = false,
  hasArtifacts,
  canSelectDirs,
  autoWidth,
  expandedNodes,
  timeStamp,
  onSelect,
  onExpand,
  urlSync = false,
}: Props) {
  const showHiddenStateReact = React.useState(false)
  const showHiddenStateUrl = useBooleanQueryParamState('showAll')
  const [showHidden, setShowHidden] = urlSync ? showHiddenStateUrl : showHiddenStateReact
  const expandedNodeArray = React.useRef(
    urlSync ? location.hash.slice(1).split(';').map(decodeURIComponent) : expandedNodes,
  )
  const handleExpand = React.useCallback(
    (pathToExpand: string, expanded: boolean | null | undefined) => {
      if (urlSync) {
        if (expanded === true) {
          // remove parent paths to avoid duplication
          expandedNodeArray.current = expandedNodeArray.current
            ?.filter(node => !isParent(node, pathToExpand))
            .concat(pathToExpand)
        } else {
          // remove path and its children
          expandedNodeArray.current = expandedNodeArray.current?.filter(
            node => node !== pathToExpand && !isParent(pathToExpand, node),
          )
        }

        window.location.hash = expandedNodeArray.current?.map(encodeURIComponent).join(';') ?? ''
      } else if (onExpand != null) {
        onExpand(pathToExpand, expanded)
      }
    },
    [onExpand, urlSync],
  )
  const handleToggleHidden = React.useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.stopPropagation()
      setShowHidden(!showHidden)
    },
    [setShowHidden, showHidden],
  )
  const ref = React.useRef<HTMLUListElement>(null)
  const hiddenRef = React.useRef()
  const isRoot = level === 0
  const {loading, files} = restApi.endpoints.getFilesListForSubpathOfBuild.useQuery(
    getArtifactsArg(buildId, path, isRoot),
    {selectFromResult: ({data}) => ({loading: data == null, files: data?.file ?? emptyArray})},
  )
  const {artifactsSize} = buildArtifactsApi.endpoints.getArtifactsSize.useQuery(
    isRoot ? {buildId, showAll: showHidden} : skipToken,
    {selectFromResult: ({data}) => ({artifactsSize: data?.size})},
  )
  const shouldFocusFirst = isRoot && files.length > 0
  const sandbox = useSandbox()
  React.useEffect(() => {
    if (shouldFocusFirst) {
      const element = ref.current

      if (element == null) {
        return
      }

      const firstItem = element.querySelector(ITEM_SELECTOR)
      sandbox.mutate(() => {
        const {documentElement} = document
        const prevScroll = documentElement?.scrollTop
        focusSelfOrChildLink(firstItem)

        if (documentElement && prevScroll != null) {
          documentElement.scrollTop = prevScroll
        }
      })
    }
  }, [sandbox, shouldFocusFirst])
  React.useEffect(() => {
    if (showHidden) {
      focusSelfOrChildLink(hiddenRef.current)
    }
  }, [showHidden])
  const onlyFolder = isOnlyFolder(files)
  const listClasses = classNames(styles.tree, {
    [styles.root]: isRoot,
    [styles.autoWidth]: autoWidth,
  })
  const toggleHiddenClasses = classNames(styles.toggleHidden, {
    [styles.secondary]: !showHidden,
  })
  const noUserArtifacts = hasArtifacts === false
  const userArtifacts = files.filter(file => !isHiddenArtifact(file))
  const hasHiddenArtifacts = files.some(isHiddenArtifact)
  const filesToRender = showHidden ? files : userArtifacts
  const artifactsHref = getArtifactsHref(buildUrl, true)
  const extensions = useAppSelector(
    state => getExtensionEndpointsByKind(state, 'artifacts'),
    shallowEqual,
  )
  const leftLevelIndentStyles = React.useMemo(() => getLeftIndent(level), [level])

  return (
    <div className={styles.container}>
      {isRoot &&
        !loading &&
        (noUserArtifacts || (hasHiddenArtifacts && userArtifacts.length === 0)) && (
          <div className={styles.noArtifacts}>
            <div>{'No user-defined artifacts in this build. '}</div>
          </div>
        )}
      {loading ? (
        !noUserArtifacts && (
          <div
            style={{
              paddingLeft: MIN_OFFSET + level * STEP,
            }}
          >
            <LoaderInline />
            {' Loading files...'}
          </div>
        )
      ) : (
        <>
          {filesToRender.length > 0 && (
            <>
              {showDownloadAllArtifactsLink && showDownloadLink === true && (
                <ArtifactsDownloadAll
                  buildId={buildId}
                  buildType={buildType}
                  showHidden={showHidden}
                  className={styles.downloadLink}
                />
              )}
              <ul
                className={listClasses}
                role={isRoot ? 'tree' : 'group'}
                aria-labelledby={labelledBy}
                ref={ref}
              >
                {filesToRender.map(file => {
                  const {name, size} = file
                  const type = getType(file)
                  const childPath = getChildPath(path, name)
                  const encodedChildPath = escapeFilePathForURL(childPath)
                  const defaultExpanded =
                    onlyFolder ||
                    expandedNodeArray.current?.some(
                      node => node === childPath || node.startsWith(getChildPath(childPath, '')),
                    )
                  return (
                    <FileTreeNode
                      key={name}
                      name={name}
                      expandable={type !== 'file'}
                      defaultExpanded={defaultExpanded}
                      path={childPath}
                      type={type}
                      icon={type}
                      size={size}
                      level={level}
                      href={
                        type === 'folder'
                          ? undefined
                          : `/repository/download/${stringifyId(buildType)}/${stringifyId(
                              buildId,
                            )}:id${encodedChildPath}`
                      }
                      extensions={extensions}
                      buildId={buildId}
                      itemRef={isHiddenArtifact(file) ? hiddenRef : null}
                      onSelect={canSelectDirs === true || type === 'file' ? onSelect : null}
                      onExpand={handleExpand}
                    >
                      {type !== 'file' && (
                        <ConnectedTree
                          buildId={buildId}
                          path={childPath}
                          level={level + 1}
                          canSelectDirs={canSelectDirs}
                          expandedNodes={expandedNodeArray.current}
                          timeStamp={timeStamp}
                          onSelect={onSelect}
                          onExpand={handleExpand}
                        />
                      )}
                    </FileTreeNode>
                  )
                })}
              </ul>
            </>
          )}
          {showHidden && hasHiddenArtifacts && (
            <div className={styles.noteHidden} style={leftLevelIndentStyles}>
              {'Hidden artifacts from the .teamcity directory are displayed'}
            </div>
          )}
          {(showToggleHidden === true || userArtifacts.length === 0) &&
            hasHiddenArtifacts &&
            artifactsHref != null && (
              <Link
                href={artifactsHref}
                className={toggleHiddenClasses}
                onPlainLeftClick={handleToggleHidden}
                style={leftLevelIndentStyles}
              >
                {showHidden ? 'Hide' : 'Show hidden artifacts'}
              </Link>
            )}
          {isRoot && artifactsSize != null && (
            <div className={styles.artifactsSize}>{`Total size: ${artifactsSize}`}</div>
          )}
          {showStorageInfo && <BuildArtifactStorageInfo buildId={buildId} />}
        </>
      )}
    </div>
  )
})
export default ConnectedTree
