import {graphql} from 'react-relay'
import type {ConcreteRequest} from 'relay-runtime'

export const pipelineHeadQuery = graphql`
  query PipelinesPagesHeadQuery($locator: String!) {
    project(projectLocator: $locator) {
      ...pipelineHeadFragment
    }
  }
` as ConcreteRequest
