import type {EntryPoint} from 'react-relay'

import type {BuildTypeId} from '../../../types'
import {makeResource} from '../../../utils/makeResource'
import {
  getPermalinksQueryVariables,
  permalinksQuery,
} from '../../common/Permalinks/Permalinks.queries'

import type {BuildTypeOverviewComponent} from './BuildTypeOverview'

type BuildTypeOverviewEntryPointParams = {
  buildTypeId: BuildTypeId
  branch?: string
}

export const BuildTypeOverviewEntryPoint: EntryPoint<
  BuildTypeOverviewComponent,
  BuildTypeOverviewEntryPointParams
> = {
  root: makeResource(
    'BuildTypeOverview',
    () =>
      import(
        /* webpackChunkName: "BuildTypeOverview", webpackPrefetch: true */ './BuildTypeOverview'
      ),
  ),
  getPreloadProps: ({buildTypeId, branch}) => ({
    queries: {
      permalinks: {
        parameters: permalinksQuery,
        variables: getPermalinksQueryVariables(buildTypeId, branch),
        options: {networkCacheConfig: {metadata: {essential: true}}},
      },
    },
  }),
}
