import type {EntryPoint} from 'react-relay'

import {stringifyBranch} from '../../../../../utils/branchNames'
import {makeResource} from '../../../../../utils/makeResource'
import {
  getPermalinksQueryVariables,
  permalinksQuery,
} from '../../../../common/Permalinks/Permalinks.queries'

import type {BuildTypeHistoryQueryParams} from './BuildTypeHistory.queries'
import {buildTypeHistoryQuery, getBuildTypeHistoryQueryVariables} from './BuildTypeHistory.queries'
import type {BuildTypeHistoryComponent} from './BuildTypeHistory.standalone'

export const BuildTypeHistoryEntryPoint: EntryPoint<
  BuildTypeHistoryComponent,
  BuildTypeHistoryQueryParams
> = {
  root: makeResource(
    'BuildTypeHistory',
    () =>
      import(
        /* webpackChunkName: "BuildTypeHistory", webpackPrefetch: true */ './BuildTypeHistory.standalone'
      ),
  ),
  getPreloadProps: params => {
    const {buildTypeId, branch} = params
    return {
      queries: {
        main: {
          parameters: buildTypeHistoryQuery,
          variables: getBuildTypeHistoryQueryVariables(params),
          options: {networkCacheConfig: {metadata: {essential: true}}},
        },
        permalinks: {
          parameters: permalinksQuery,
          variables: getPermalinksQueryVariables(buildTypeId, stringifyBranch(branch)),
          options: {networkCacheConfig: {metadata: {essential: true}}},
        },
      },
    }
  },
}
