/**
 * @generated SignedSource<<3d2a1f610eb9133787658712960ad3da>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditPipelinePageLastRunQuery$variables = {
  locator: string;
};
export type EditPipelinePageLastRunQuery$data = {
  readonly builds: {
    readonly build: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"EditPipelinePageDependencyFragment">;
    }> | null;
  } | null;
};
export type EditPipelinePageLastRunQuery = {
  response: EditPipelinePageLastRunQuery$data;
  variables: EditPipelinePageLastRunQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "locator"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "locator",
    "variableName": "locator"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rawId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditPipelinePageLastRunQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Builds",
        "kind": "LinkedField",
        "name": "builds",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Build",
            "kind": "LinkedField",
            "name": "build",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EditPipelinePageDependencyFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditPipelinePageLastRunQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Builds",
        "kind": "LinkedField",
        "name": "builds",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Build",
            "kind": "LinkedField",
            "name": "build",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Builds",
                "kind": "LinkedField",
                "name": "snapshotDependencies",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Build",
                    "kind": "LinkedField",
                    "name": "build",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "state",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Comment",
                        "kind": "LinkedField",
                        "name": "canceledInfo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "timestamp",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "failedToStart",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "personal",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "detachedFromAgent",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "parallelized",
                        "storageKey": null
                      },
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BuildTypeRest",
                        "kind": "LinkedField",
                        "name": "buildType",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7c62ab4500a47b2e98fa6cacefe4f97d",
    "id": null,
    "metadata": {},
    "name": "EditPipelinePageLastRunQuery",
    "operationKind": "query",
    "text": "query EditPipelinePageLastRunQuery(\n  $locator: String!\n) {\n  builds(locator: $locator) {\n    build {\n      ...EditPipelinePageDependencyFragment\n      id\n    }\n  }\n}\n\nfragment EditPipelinePageDependencyFragment on Build {\n  snapshotDependencies {\n    build {\n      ...JobTileLastRunFragment\n      ...JobStepLastRunFragment\n      buildType {\n        name\n        id\n      }\n      canceledInfo {\n        timestamp\n      }\n      id\n    }\n  }\n}\n\nfragment JobStepLastRunFragment on Build {\n  status\n  rawId\n}\n\nfragment JobTileLastRunFragment on Build {\n  ...useBuildStatusIconFragment\n  parallelized\n  status\n}\n\nfragment useBuildStatusIconFragment on Build {\n  status\n  state\n  canceledInfo {\n    timestamp\n  }\n  failedToStart\n  personal\n  detachedFromAgent\n  user {\n    rawId\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "bd7aaa5a6e2d53a51ae5bd3a5ceb273a";

export default node;
