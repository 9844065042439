import * as React from 'react'

import {getBuild, getBuildType} from '../selectors'
import type {ActiveEntityProps} from '../types'

import {useAppSelector} from 'src/hooks/react-redux'

export const EntityContext: React.Context<ActiveEntityProps> = React.createContext(
  Object.freeze({}),
)
type Props = ActiveEntityProps & {
  seqKey?: string
  children: React.ReactNode
}

function EntityProviderComponent({
  projectId,
  buildTypeId,
  buildId,
  agentId,
  agentPoolId,
  agentTypeId,
  testId,
  children,
}: Props) {
  const resolvedBuildTypeId = useAppSelector(
    state => buildTypeId ?? getBuild(state, buildId)?.buildType,
  )
  const resolvedProjectId = useAppSelector(
    state => projectId ?? getBuildType(state, buildTypeId)?.projectId,
  )
  const contextValue = React.useMemo(
    () => ({
      projectId: resolvedProjectId,
      buildTypeId: resolvedBuildTypeId,
      buildId,
      agentId,
      agentPoolId,
      agentTypeId,
      testId,
    }),
    [agentId, agentPoolId, agentTypeId, buildId, resolvedBuildTypeId, resolvedProjectId, testId],
  )
  return <EntityContext.Provider value={contextValue}>{children}</EntityContext.Provider>
}

export const EntityProvider = React.memo<Props>(EntityProviderComponent)
