import type {ReactNode} from 'react'
import type {PreloadedQuery} from 'react-relay'
import {graphql, useFragment, usePreloadedQuery} from 'react-relay'

import type {currentUserKeyFragment$key} from '../../../contexts/__generated__/currentUserKeyFragment.graphql'
import {CurrentUserKeyContext, currentUserKeyFragment} from '../../../contexts/currentUserKey'

import type {ClassicUIQueryLoaderQuery} from './__generated__/ClassicUIQueryLoaderQuery.graphql'

export const classicUIQueryLoaderQuery = graphql`
  query ClassicUIQueryLoaderQuery {
    user(userLocator: "current") {
      ...currentUserKeyFragment
    }
  }
`

type Props = {children: ReactNode; query: PreloadedQuery<ClassicUIQueryLoaderQuery>}
export function ClassicUIQueryLoader({children, query}: Props) {
  const data = usePreloadedQuery(classicUIQueryLoaderQuery, query)
  const user: currentUserKeyFragment$key | null = data.user
  const userKey = useFragment(currentUserKeyFragment, user)
  return <CurrentUserKeyContext.Provider value={userKey}>{children}</CurrentUserKeyContext.Provider>
}
