import TagIcon from '@jetbrains/icons/tag.svg'
import type {TagRenderProps} from '@jetbrains/ring-ui/components/tag/tag'
import Tag from '@jetbrains/ring-ui/components/tag/tag'
import classnames from 'classnames'
import * as React from 'react'
import type {ReactNode, SyntheticEvent} from 'react'

import {getBuildTypeHref} from '../../../routes'
import {getBuildTypeLinks, getIsSakuraUI} from '../../../selectors'
import type {BuildId, BuildTypeId} from '../../../types'
import {resolveWebEntityLink} from '../../../utils/entityLinks'
import {Modes} from '../../pages/BuildTypePage/BuildTypeOverviewTab/BuildTypeOverviewTab.modes'
import {removeBuildTag} from '../BuildActionsDropdown/TagDialog/TagDialog.actions'
import Link from '../Link/Link'
import MiddleEllipsis from '../MiddleEllipsis/MiddleEllipsis'
import RouterLink from '../RouterLink/RouterLink'
import SvgIcon from '../SvgIcon/SvgIcon'

import styles from './BuildTag.css'

import {useAppDispatch, useAppSelector} from 'src/hooks/react-redux'

type Props = {
  onSelect?: (() => unknown) | undefined
  className?: string
  buildTypeId?: BuildTypeId | undefined
  buildId?: BuildId | undefined
  label?: string | null | undefined
  multiple?: boolean
  selected?: boolean
  href?: string
  removable?: boolean
}

const useHref = ({href, label, buildTypeId, selected = false}: Props) => {
  const baseHref = useAppSelector(state => {
    const links = getBuildTypeLinks(state, buildTypeId)
    return resolveWebEntityLink(links) ?? ''
  })

  if (href != null) {
    return href
  }

  if (label == null) {
    return '#'
  }

  const params = new URLSearchParams(location.search)
  params.set('tab', 'buildTypeHistoryList')

  if (selected) {
    params.delete('tag')
  } else {
    params.set('tag', label)
  }

  return `${baseHref}&${params.toString()}`
}

function BuildTag(props: Props) {
  const dispatch = useAppDispatch()
  const {
    className,
    label,
    multiple,
    selected = false,
    buildTypeId,
    onSelect,
    removable,
    buildId,
  } = props
  const stopPropagation = React.useCallback((e: SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation()
  }, [])
  const isSakuraUI = useAppSelector(getIsSakuraUI)
  const href = useHref(props)
  const classes = classnames(styles.tag, className, {
    [styles.selected]: selected,
    [styles.multiple]: multiple,
  })

  if (label == null) {
    return null
  }

  if (multiple === true) {
    return (
      <span className={classes} onClick={stopPropagation} role="presentation">
        <span>
          <SvgIcon icon={TagIcon} className={styles.icon} />
        </span>
        {label}
      </span>
    )
  }

  let render: ((props: TagRenderProps) => ReactNode) | undefined
  if (onSelect != null) {
    render = undefined
  } else if (isSakuraUI && buildTypeId != null) {
    render = ({children, ref, ...tagProps}) => (
      <RouterLink
        innerClassName={styles.linkInner}
        to={getBuildTypeHref(buildTypeId)}
        params={{
          tag: selected ? null : label,
          mode: Modes.BUILDS,
        }}
        withMergeParams
        {...tagProps}
      >
        {() => children}
      </RouterLink>
    )
  } else {
    render = ({children, ref, ...tagProps}) => (
      <Link href={href} {...tagProps}>
        {() => children}
      </Link>
    )
  }

  return (
    <Tag
      angled
      className={classes}
      onClick={onSelect}
      onRemove={
        buildId != null ? () => dispatch(removeBuildTag(buildId, label, buildTypeId)) : undefined
      }
      readOnly={!removable || buildId == null}
      render={render}
    >
      <MiddleEllipsis tailLength={2}>{label}</MiddleEllipsis>
    </Tag>
  )
}

export default React.memo<Props>(BuildTag)
