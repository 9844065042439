import type {EntryPoint} from 'react-relay'
import type {LoaderFunctionArgs} from 'react-router'

import {BuildTypePageTabNamesEnum, defaultBuildTypePageTabName} from '../../../types'
import type {ThinQueryParamsObject} from '../../../types/entryPoints'
import {parseBranch} from '../../../utils/branchNames'
import {makeResource} from '../../../utils/makeResource'
import {queryToObject} from '../../../utils/queryParams'
import {
  getPermalinksQueryVariables,
  permalinksQuery,
} from '../../common/Permalinks/Permalinks.queries'

import {
  buildTypeHistoryQuery,
  getBuildTypeHistoryQueryVariables,
} from './BuildTypeOverviewTab/BuildTypeHistory/BuildTypeHistory.queries'
import {defaultMode, Modes} from './BuildTypeOverviewTab/BuildTypeOverviewTab.modes'
import type {BuildTypePageContainerComponent, Queries} from './BuildTypePage'

export const BuildTypePageEntryPoint: EntryPoint<
  BuildTypePageContainerComponent,
  LoaderFunctionArgs
> = {
  root: makeResource(
    'BuildTypePage',
    () => import(/* webpackChunkName: "BuildTypePage", webpackPrefetch: true */ './BuildTypePage'),
  ),
  getPreloadProps: ({params, request}) => {
    const {buildTypeId} = params
    const url = new URL(request.url)
    const {
      branch,
      buildTypeTab = defaultBuildTypePageTabName,
      mode = defaultMode,
    } = queryToObject(url.search)
    const queries: ThinQueryParamsObject<Queries> = {}
    if (
      buildTypeId != null &&
      buildTypeTab === BuildTypePageTabNamesEnum.OVERVIEW &&
      mode === Modes.BUILDS
    ) {
      queries.buildTypeHistory = {
        parameters: buildTypeHistoryQuery,
        variables: getBuildTypeHistoryQueryVariables({
          buildTypeId,
          branch: parseBranch(branch),
          withRunningAndQueued: true,
        }),
        options: {networkCacheConfig: {metadata: {essential: true}}},
      }
      queries.permalinks = {
        parameters: permalinksQuery,
        variables: getPermalinksQueryVariables(buildTypeId, branch),
        options: {networkCacheConfig: {metadata: {essential: true}}},
      }
    }
    return {queries}
  },
}
