import {graphql} from 'react-relay'
import type {ConcreteRequest} from 'relay-runtime'

export const appContainerQuery = graphql`
  query AppContainerQuery {
    user(userLocator: "current") {
      rawId
      properties {
        property {
          name
          value
        }
      }
      ...AppFragment
    }
  }
` as ConcreteRequest
