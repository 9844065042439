import type {EntryPoint, EntryPointComponent} from 'react-relay'

import {makeResource} from '../../../../utils/makeResource'
import type {AgentsPagesQueries} from '../AgentsPages.queries'
import {agentsPagesQueries} from '../AgentsPages.queries'

export const AgentsOverviewPageEntryPoint: EntryPoint<EntryPointComponent<AgentsPagesQueries, {}>> =
  {
    root: makeResource(
      'AgentsOverviewPage',
      () =>
        import(
          /* webpackChunkName: "AgentsOverviewPage", webpackPrefetch: true */ './AgentsOverviewPage'
        ),
    ),
    getPreloadProps: () => ({queries: agentsPagesQueries}),
  }
