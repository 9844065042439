import type {PreloadedState} from 'redux'

import {getBuildlogStateToPersist} from '../components/packages/BuildLog/BuildLog.selectors'
import {getHintsStateToPersist} from '../components/packages/Hints/Hints.selectors'
import {getPipelinesStateToPersist} from '../components/pages/PipelinesPages/selectors'
import type {State} from '../reducers/types'

export type PersistedState = PreloadedState<State>
export const getStateToPersist = (state: State): PersistedState => {
  const {blocks, clientId, sidebar, parameterGroups, compareBuilds}: State = state
  return {
    blocks,
    sidebar,
    compareBuilds,
    buildLog: getBuildlogStateToPersist(state),
    clientId,
    pipelines: getPipelinesStateToPersist(state),
    hints: getHintsStateToPersist(state),
    parameterGroups,
  }
}
