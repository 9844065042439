import type {EntryPoint} from 'react-relay'

import type {ProjectId} from '../../../../../types'
import {makeResource} from '../../../../../utils/makeResource'
import {getUseIsReadonlyQueryVariables, UseIsReadonlyQuery} from '../../CleanupProjectPage.queries'

import type {RulesStandaloneComponent} from './Rules.standalone'

export const RulesEntryPoint: EntryPoint<RulesStandaloneComponent, ProjectId> = {
  root: makeResource(
    'Rules',
    () => import(/* webpackChunkName: "Rules", webpackPrefetch: true */ './Rules.standalone'),
  ),
  getPreloadProps: projectId => ({
    queries: {
      readOnlyProjects: {
        parameters: UseIsReadonlyQuery,
        variables: getUseIsReadonlyQueryVariables(projectId),
        options: {networkCacheConfig: {metadata: {essential: true}}},
      },
    },
  }),
}
