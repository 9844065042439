import type {
  AddTagsToMultipleBuildsApiArg,
  Branch,
  Build,
  GetAllBuildsApiArg,
  GetAllBuildsNormalizedApiArg,
  GetBuildApiArg,
  GetBuildNormalizedAsListApiArg,
  PinMultipleBuildsApiArg,
  SetBuildTagsApiArg,
  SetMultipleBuildCommentsApiArg,
} from '../services/rest'
import type {BuildId, BuildTypeId, RequestOptionsParams, Tag} from '../types'
import {stringifyId} from '../types'
import type {RunCustomBuildStringParams} from '../types/BS_types'
import {internalProps} from '../types/BS_types'
import {noop} from '../utils/empty'

import {compatibleAgentsFields} from './buildCompatibleAgents'
import {buildTypeFields} from './buildTypes'
import {getBranchLocator} from './locators'

const CHANGES_LIMIT: number = internalProps['teamcity.internal.buildChangesPopupLimit']
const approvalWarming = 'approvalInfo(status),'
export const getBuildFields = (options?: RequestOptionsParams | null | undefined): string => {
  const withBuildTypesDetails = options?.withBuildTypeDetails
  let fields =
    'id,number,branchName,defaultBranch,queuedDate,startDate,finishDate,history,composite,' +
    'parallelized,' +
    'links(link(type,relativeUrl)),' +
    'comment(text,timestamp,user(id,name,username)),' +
    'statusChangeComment(text,timestamp,user(id,name,username)),' +
    'statusText,status,state,failedToStart,personal,detachedFromAgent,finishOnAgentDate,' +
    'pinned,pinInfo(text,timestamp,user(id,name,username)),' +
    'user(id,name,username),' +
    'customization,' +
    'canceledInfo(text,user(id,name,username)),' +
    `${approvalWarming}` +
    'agent(name,id,links(link(type,relativeUrl)),environment(osType),typeId,connected,pool(id,name)),' +
    'tags(tag(name,private),$locator(private:any,owner:current)),' +
    'artifacts($locator(count:1),count:($optional)),' +
    'limitedChangesCount($optional),' +
    `buildType(${
      withBuildTypesDetails === true
        ? buildTypeFields({
            withShortProjectDetails: options?.withShortProjectDetails,
            withLinks: true,
          }).join(',')
        : 'id'
    })`

  if (options?.withSnapshotDependencies === true) {
    fields += ',snapshot-dependencies(count,build(id))'
  } else {
    fields += ',snapshot-dependencies(count:(1))'
  }

  if (options?.withDownloadedArtifactsFrom != null) {
    fields += `,downloadedArtifacts($locator(id:${options.withDownloadedArtifactsFrom}),downloadInfo(artifactInfo(path)))`
  }

  if (options?.withRunningInfo === true) {
    // prettier-ignore
    fields +=
      ',running-info(' +
        'percentageComplete,' +
        'elapsedSeconds,' +
        'estimatedTotalSeconds,' +
        'leftSeconds,' +
        'probablyHanging,' +
        'lastActivityTime,' +
        'outdated,' +
        'outdatedReasonBuild(number,links(link(type,relativeUrl)))' +
      ')'
  }

  if (options?.withQueuedInfo === true) {
    // prettier-ignore
    fields +=
      ',waitReason,queuePosition,startEstimate,finishEstimate,' +
      'plannedAgent(name,id,environment(osType),typeId,pool(id,name)),' +
      'delayedByBuild(id,number,status,state,failedToStart,personal,canceledInfo,buildType(id)),' +
      'triggered(' +
        'date,' +
        'displayText,' +
        `buildType(${withBuildTypesDetails === true ? buildTypeFields({withLinks: true}).join(',') : 'id'})` +
      ')'
  }

  return fields
}

export const getBuildsArg = (
  locator = 'state:any',
  options?: RequestOptionsParams,
  onProgress: (build: Build) => void = noop,
): GetAllBuildsNormalizedApiArg => ({
  ...options,
  locator,
  fields: `count,build(${getBuildFields(options)})`,
  onProgress,
})

export const getBuildsDetailsArg = (
  locatorToStore: string,
  locatorToFetch = 'state:any',
  options: RequestOptionsParams | null | undefined,
  onProgress: (arg0: Build) => unknown = noop,
): GetAllBuildsNormalizedApiArg => ({
  ...options,
  detailsLocatorToStore: locatorToStore,
  locator: locatorToFetch,
  fields: `build(${[
    'id',
    `changes($locator(count:${CHANGES_LIMIT}),change(id,username,commiter(users(user(id,name,username,avatars))))),artifactDependencyChanges(count)`,
    options?.withQueuedInfo === true ? compatibleAgentsFields : null,
  ]
    .filter(Boolean)
    .join(',')})`,
  onProgress,
})

export const getBuildsCountArg = (
  locator: string,
  count: number,
  countInLocator: boolean,
  lookupLimit: number,
  options?: RequestOptionsParams | null | undefined,
  inBackground?: boolean,
): GetAllBuildsApiArg => ({
  locator: `${locator},${countInLocator ? '' : `count:${count},`}lookupLimit:${lookupLimit}`,
  fields: 'nextHref,count',
  inBackground,
  count,
  ...options,
})

export const getSingleBuildArg = (
  buildLocator: string,
  options?: RequestOptionsParams,
): GetBuildNormalizedAsListApiArg => {
  const optionsWithDefaults = {
    withRunningInfo: true,
    withQueuedInfo: true,
    withBuildTypeDetails: false,
    withSnapshotDependencies: false,
    essential: true,
    ...options,
  }
  return {
    buildLocator,
    fields: getBuildFields(optionsWithDefaults),
    ...optionsWithDefaults,
    locators: [buildLocator],
  }
}

export const getBuildTriggerBuildArg = (buildId: BuildId): GetBuildApiArg => ({
  buildLocator: `id:${stringifyId(buildId)}`,
  fields: 'triggered(build(id,number,links(link(type,relativeUrl))))',
})

const getMultipleLocator = (buildIds: ReadonlyArray<BuildId>, withDependencies?: boolean) =>
  buildIds
    .map(
      id =>
        `item(id:${id})${
          withDependencies ? `,item(defaultFilter:false,snapshotDependency:(to:(id:${id})))` : ''
        }`,
    )
    .join(',')

export const getSaveBuildsCommentArg = (
  buildIds: ReadonlyArray<BuildId>,
  text: string,
): SetMultipleBuildCommentsApiArg => ({
  buildLocator: getMultipleLocator(buildIds),
  fields: 'operationResult(related(build(id,comment(text,timestamp,user(id,name,username)))))',
  body: text,
})

export const getArtifactDependencyExistsLocator = (buildLocator: string, isDownloaded: boolean) => {
  const state = isDownloaded ? 'state:(running:true,finished:true)' : 'state:finished'
  const direction = isDownloaded ? 'to' : 'from'

  return `defaultFilter:false,${state},artifactDependency:(${direction}:(${buildLocator}),recursive:false),count:1`
}

export const getPinBuildsArg = (
  buildIds: ReadonlyArray<BuildId>,
  status: boolean,
  commentText: string | undefined,
  withDependencies: boolean,
): PinMultipleBuildsApiArg => ({
  buildLocator: getMultipleLocator(buildIds, withDependencies),
  fields:
    'operationResult(related(build(id,pinned,pinInfo(text,timestamp,user(id,name,username)))))',
  pinInfo: {
    status,
    comment: {
      text: commentText,
    },
  },
})

export const getChangeTagsArg = (
  buildId: BuildId,
  tags: ReadonlyArray<Tag>,
  prevTags: ReadonlyArray<Tag>,
  buildTypeId?: BuildTypeId,
): SetBuildTagsApiArg => ({
  buildLocator: `id:${stringifyId(buildId)}`,
  buildTypeId,
  locator: 'private:any,owner:current',
  tags: {tag: [...tags]},
  prevTags: {tag: [...prevTags]},
})

const getAddTagsArgByLocator = (
  locator: string,
  tags: ReadonlyArray<Tag>,
  buildTypeId?: BuildTypeId,
): AddTagsToMultipleBuildsApiArg => ({
  buildLocator: locator,
  buildTypeId,
  fields:
    'operationResult(related(build(id,tags(tag(name,private),$locator(private:any,owner:current)))))',
  tags: {tag: [...tags]},
})

export const getAddTagsToDependenciesArg = (
  buildId: BuildId,
  tags: ReadonlyArray<Tag>,
  buildTypeId?: BuildTypeId,
) =>
  getAddTagsArgByLocator(
    `defaultFilter:false,snapshotDependency:(to:(id:${stringifyId(buildId)}))`,
    tags,
    buildTypeId,
  )

export const getAddTagsArg = (
  buildIds: ReadonlyArray<BuildId>,
  tags: ReadonlyArray<Tag>,
  withDependencies?: boolean,
  buildTypeId?: BuildTypeId,
) => getAddTagsArgByLocator(getMultipleLocator(buildIds, withDependencies), tags, buildTypeId)

export type TriggerBuildOptions = {
  promoteId: BuildId | null | undefined
  stateKey?: string
  init?: boolean
  initFromBuild?: BuildId
}
export const getPromoteOptions = (
  promoteId: BuildId | null | undefined,
): RunCustomBuildStringParams =>
  promoteId != null
    ? {
        dependOnPromotionIds: stringifyId(promoteId),
        init: 'true',
        stateKey: 'promote',
        redirectTo: '',
        modificationId: 'auto',
      }
    : Object.freeze({})

export const getLastSuccessfulBuildLocator = (props: {
  buildTypeId: BuildTypeId
  buildId: BuildId
  branch: Branch
  inPath?: boolean
}) => {
  const {buildTypeId, buildId, branch, inPath} = props

  return [
    `buildType:(id:${stringifyId(buildTypeId)})`,
    `startDate:(build:(id:${stringifyId(buildId)}),condition:before)`,
    getBranchLocator(branch, inPath),
    'state:finished,status:SUCCESS',
    'defaultFilter:false',
    'count:1',
  ]
    .filter(Boolean)
    .join(',')
}
