import type {EntryPoint, EntryPointComponent} from 'react-relay'

import {makeResource} from '../../../../utils/makeResource'
import type {AgentsPagesQueries} from '../AgentsPages.queries'
import {agentsPagesQueries} from '../AgentsPages.queries'

export const AgentTypePageEntryPoint: EntryPoint<EntryPointComponent<AgentsPagesQueries, {}>> = {
  root: makeResource(
    'AgentTypePage',
    () => import(/* webpackChunkName: "AgentTypePage", webpackPrefetch: true */ './AgentTypePage'),
  ),
  getPreloadProps: () => ({queries: agentsPagesQueries}),
}
