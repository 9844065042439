import Dropdown from '@jetbrains/ring-ui/components/dropdown/dropdown'
import type {PopupMenuAttrs} from '@jetbrains/ring-ui/components/popup-menu/popup-menu'
import * as React from 'react'

import IconButton from '../IconButton/IconButton'
import PopupMenu from '../Popup/PopupMenu.lazy'

import {UNIT, useHelpDropdownContent} from './HelpDropdown.consts'

import styles from './HelpDropdown.css'

export function Popup(props: PopupMenuAttrs) {
  return <PopupMenu {...props} data={useHelpDropdownContent()} left={-UNIT} />
}

function HelpDropdown() {
  return (
    <>
      <Dropdown
        className={styles.dropdown}
        anchor={({active}) => <IconButton title="Help" icon="help-20px" primary={active} />}
        hoverMode
      >
        <Popup />
      </Dropdown>
    </>
  )
}

export default React.memo<{}>(HelpDropdown)
