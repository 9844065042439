import {Suspense} from 'react'
import {connect} from 'react-redux'

import {useUpdateResults} from '../../../contexts/update'
import type {State} from '../../../reducers/types'
import {getBuild, getBuildType} from '../../../selectors'
import {restApi} from '../../../services/rest'
import type {BuildId, ProjectId} from '../../../types'

import MoveToTop from './MoveToTop'

type Props = {
  buildId: BuildId
  className?: string
}

type StateProps = {
  projectId: ProjectId | undefined
  isDraggable: boolean
}

const mapStateToProps = (state: State, props: Props): StateProps => {
  const {buildType, queuePosition = 0} = getBuild(state, props.buildId) || {}
  const {projectId} = getBuildType(state, buildType) || {}
  const isDraggable = queuePosition > 1
  return {
    projectId,
    isDraggable,
  }
}

function MoveToTopContainer(props: Props & StateProps) {
  const [moveBuildToTop] = restApi.endpoints.setQueuedBuildPosition.useMutation()
  const updateBuilds = useUpdateResults()

  return (
    <Suspense>
      <MoveToTop
        {...props}
        updateBuilds={updateBuilds}
        clickHandler={(id: BuildId) => moveBuildToTop({queuePosition: '1', build: {id}})}
      />
    </Suspense>
  )
}

export default connect(mapStateToProps)(MoveToTopContainer)
