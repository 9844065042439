import * as React from 'react'

import {restApi} from '../../../services/rest'

import {BuildApprovalViewContext} from './BuildApproval.context'
import {getBuildApprovalArg} from './BuildApproval.rest'
import type {Props} from './BuildApproval.types'
import {BuildApprovalViewMode} from './BuildApproval.types'
import ApprovalClassic from './Classic/BuildApproval.classic'
import ApprovalInline from './Inline/BuildApproval'
import ApprovalPopup from './Popup/DetailsApproval'

export default function BuildApprovalLayout(props: Props) {
  const {buildId, view} = props

  const {approvalInfo} = restApi.endpoints.getApprovalInfo.useQuery(getBuildApprovalArg(buildId), {
    selectFromResult: ({data}) => ({approvalInfo: data}),
  })

  let Tag
  let contextValue

  switch (view) {
    case BuildApprovalViewMode.CLASSIC_UI:
      Tag = ApprovalClassic
      contextValue = BuildApprovalViewMode.INLINE
      break
    case BuildApprovalViewMode.POPUP:
      Tag = ApprovalPopup
      contextValue = BuildApprovalViewMode.POPUP
      break
    default:
      contextValue = BuildApprovalViewMode.INLINE
      Tag = ApprovalInline
  }

  return (
    <BuildApprovalViewContext.Provider value={contextValue}>
      <Tag approvalInfo={approvalInfo} buildId={buildId} />
    </BuildApprovalViewContext.Provider>
  )
}
