import LoaderInline from '@jetbrains/ring-ui/components/loader-inline/loader-inline'
import classNames from 'classnames'
import {memo, useCallback, useEffect} from 'react'

import TransformText from '../../../../containers/TransformText'
import {useIsAutodetectTimeZone} from '../../../../hooks/useIsAutodetectTimeZone'
import {displayDate} from '../../../../utils/dateTimeUtils'
import {emptyArray} from '../../../../utils/empty'
import {subscribeOnProjectEvents} from '../../../../utils/subscriber'
import {
  BUILD_TYPE_RESPONSIBILITY_CHANGES,
  PROBLEM_RESPONSIBILITY_CHANGED,
  TEST_RESPONSIBILITY_CHANGED,
} from '../../../../utils/subscriptionEvents'
import Avatar, {Size} from '../../../common/Avatar/Avatar.container'
import {investigationHistoryApi} from '../InvestigationHistory.rest'
import type {InvestigationHistory} from '../InvestigationHistory.types'
import {
  getInvestigationHistoryActionTextByType,
  getInvestigationHistoryResolveMethod,
} from '../InvestigationHistory.utils'

import type {Props} from './InvestigationHistoryList.types'

import styles from './InvestigationHistoryList.css'

function InvestigationHistoryList({className, locator, projectInternalId}: Props) {
  const {investigations, isReady, refetch} = investigationHistoryApi.endpoints.get.useQuery(
    locator,
    {
      selectFromResult: ({data, isSuccess}) => ({
        investigations: data ?? emptyArray,
        isReady: isSuccess,
      }),
    },
  )
  useEffect(
    () =>
      projectInternalId != null
        ? subscribeOnProjectEvents(
            projectInternalId,
            [
              TEST_RESPONSIBILITY_CHANGED,
              PROBLEM_RESPONSIBILITY_CHANGED,
              BUILD_TYPE_RESPONSIBILITY_CHANGES,
            ],
            refetch,
          )
        : undefined,
    [projectInternalId, refetch],
  )

  const isAutodetectTimeZone = useIsAutodetectTimeZone()

  const renderInvestigation = useCallback(
    (investigation: InvestigationHistory) => {
      const resolveMethod = getInvestigationHistoryResolveMethod(investigation)
      const actorName = investigation.actor?.descriptiveName ?? investigation.actor?.userName
      return (
        <div key={investigation.timestamp} className={styles.investigation}>
          <div className={styles.investigationHeader}>
            {investigation.actor != null && (
              <Avatar
                user={{
                  id: investigation.actor.userId,
                  username: investigation.actor.userName,
                  name: investigation.actor.descriptiveName,
                  avatars: {
                    urlToSize20: investigation.actor.avatar20Url,
                    urlToSize40: investigation.actor.avatar40Url,
                  },
                }}
                size={Size.Size20}
              />
            )}
            <span className={styles.user}>{actorName ?? 'TeamCity'}</span>
            <span className={styles.timestamp}>
              {` ${displayDate(investigation.timestamp, isAutodetectTimeZone, true, true)}`}
            </span>
          </div>
          <div className={styles.investigationContent}>
            <TransformText
              text={getInvestigationHistoryActionTextByType(investigation)}
              className={styles.comment}
            />
            <span className={styles.resolveMethod}>
              {resolveMethod != null && `resolve method: ${resolveMethod}`}
            </span>
          </div>
        </div>
      )
    },
    [isAutodetectTimeZone],
  )
  return (
    <div className={classNames(className, styles.content)}>
      {isReady ? (
        investigations.length > 0 ? (
          [...investigations].reverse().map(investigation => renderInvestigation(investigation))
        ) : (
          <div className={styles.emptyHistory}>{'History is empty'}</div>
        )
      ) : (
        <LoaderInline>{'Loading...'}</LoaderInline>
      )}
    </div>
  )
}

export default memo<Props>(InvestigationHistoryList)
