import type {Location} from 'react-router-dom'

import {queryToObject} from '../../../../../../utils/queryParams'

export enum TestFilterStatus {
  ALL = 'all',
  FAILED = 'failed',
  IGNORED = 'ignored',
  MUTED = 'muted',
  PASSED = 'passed',
}

export const TestStatusName = {
  [TestFilterStatus.FAILED]: 'failure',
  [TestFilterStatus.IGNORED]: 'ignored',
  [TestFilterStatus.MUTED]: 'muted failure',
  [TestFilterStatus.PASSED]: 'success',
} as const

const isTestFilterStatus = (value: unknown): value is TestFilterStatus =>
  Object.values(TestFilterStatus).includes(value as TestFilterStatus)

export const getStatusQueryParam = (location: Location): TestFilterStatus => {
  const status = queryToObject(location.search).status
  return isTestFilterStatus(status) ? status : TestFilterStatus.ALL
}
