import batchVisible from '../../../actions/batchVisible'
import {buildDetailsQueue} from '../../../actions/buildDetailsQueue'
import type {AppThunk} from '../../../actions/types'
import {normalizeBuildArtifacts} from '../../../rest/schemata'
import type {Build} from '../../../services/rest'
import {restApi} from '../../../services/rest'
import {toBuildId} from '../../../types'
import type {BuildId} from '../../../types'
import {Namespace} from '../../../utils/namespace'
import {unsubscribeOnSuccess} from '../../../utils/refetchable'

import {getBuildArtifactsArg} from './BuildArtifacts.rest'
import {buildArtifacts} from './BuildArtifacts.slice'

export const receiveBuildArtifacts = (data: ReadonlyArray<Build>) =>
  buildArtifacts.actions.receive(normalizeBuildArtifacts(data))
export const fetchBuildArtifactsCountQueue: (id: BuildId) => AppThunk<void> = batchVisible({
  queue: buildDetailsQueue,
  namespace: Namespace.ARTIFACTS,
  request: (id, dispatch) =>
    unsubscribeOnSuccess(
      dispatch(restApi.endpoints.getBuild.initiate(getBuildArtifactsArg(toBuildId(id)))),
    ).then(({data}) => {
      if (data == null) {
        return []
      }
      if (data.artifacts?.count == null) {
        return [
          {
            ...data,
            artifacts: {
              count: 0,
            },
          },
        ]
      }

      return [data]
    }),
  action: receiveBuildArtifacts,
})
