import LoaderInline from '@jetbrains/ring-ui/components/loader-inline/loader-inline'
import * as React from 'react'

import {ApprovalStatuses} from '../../../../services/rest'
import BuildApprovalReviewers from '../BuildApproval.reviewers'
import type {ViewProps} from '../BuildApproval.types'
import {checkIfUserCanViewApprovalInfo} from '../BuildApproval.utils'

import ApprovalControls from './BuildApproval.controls'

function BuildApproval({buildId, approvalInfo}: ViewProps) {
  const title = approvalInfo?.status === ApprovalStatuses.APPROVED ? 'Approved' : 'Approval'
  const canViewApprovalInfo = checkIfUserCanViewApprovalInfo(approvalInfo)

  if (!approvalInfo) {
    return (
      <tr>
        <th>{title}</th>
        <td>
          <span>
            {'Loading '}
            <LoaderInline />
          </span>
        </td>
      </tr>
    )
  }

  return (
    <>
      <tr>
        <th>{title}</th>
        <td>
          {canViewApprovalInfo ? (
            <BuildApprovalReviewers approvalInfo={approvalInfo} />
          ) : (
            <ApprovalControls approvalInfo={approvalInfo} buildId={buildId} />
          )}
        </td>
      </tr>
      <tr>
        <td />
        <td>
          {canViewApprovalInfo ? (
            <ApprovalControls approvalInfo={approvalInfo} buildId={buildId} />
          ) : null}
        </td>
      </tr>
    </>
  )
}

export default React.memo(BuildApproval)
