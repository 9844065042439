/**
 * @generated SignedSource<<5a61e18790f3cafd5e2ecb82939178a1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useSetUserPropertyMutation$variables = {
  name: string;
  value?: string | null;
};
export type useSetUserPropertyMutation$data = {
  readonly setUserProperty: string | null;
};
export type useSetUserPropertyMutation = {
  response: useSetUserPropertyMutation$data;
  variables: useSetUserPropertyMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "value"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "value"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      },
      {
        "kind": "Literal",
        "name": "userLocator",
        "value": "current"
      }
    ],
    "kind": "ScalarField",
    "name": "setUserProperty",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useSetUserPropertyMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useSetUserPropertyMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d2d93819beb59d3fabbb0ea63fd635bd",
    "id": null,
    "metadata": {},
    "name": "useSetUserPropertyMutation",
    "operationKind": "mutation",
    "text": "mutation useSetUserPropertyMutation(\n  $name: String!\n  $value: String\n) {\n  setUserProperty(userLocator: \"current\", name: $name, input: $value)\n}\n"
  }
};
})();

(node as any).hash = "1da6363e9f5423a4cf89e27bde56d157";

export default node;
