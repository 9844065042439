import type {
  GetAllFederationServersApiArg,
  ReplaceAllFederationServersApiArg,
} from '../services/rest'

export const federationServersArg: GetAllFederationServersApiArg = {fields: 'server'}

export const getReplaceFederationServersArg = (
  servers: readonly string[],
): ReplaceAllFederationServersApiArg => ({
  servers: {
    server: servers.map(url => ({
      url,
    })),
  },
})
