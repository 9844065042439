import type {EntryPoint} from 'react-relay'
import type {LoaderFunctionArgs} from 'react-router'

import {ProjectPageTabNamesEnum} from '../../../types'
import type {ThinNestedEntryPointParamsObject} from '../../../types/entryPoints'
import {makeResource} from '../../../utils/makeResource'
import {queryToObject} from '../../../utils/queryParams'

import {ProjectInvestigationsTabEntryPoint} from './ProjectInvestigationsTab/ProjectInvestigationsTab.entryPoint'
import type {ProjectPageOuterContainerComponent, NestedEntryPoints} from './ProjectPage.container'
import {projectPageQuery} from './ProjectPage.queries'

export const ProjectPageEntryPoint: EntryPoint<
  ProjectPageOuterContainerComponent,
  LoaderFunctionArgs
> = {
  root: makeResource(
    'ProjectPage',
    () =>
      import(
        /* webpackChunkName: "ProjectPage", webpackPrefetch: true */ './ProjectPage.container'
      ),
  ),
  getPreloadProps: ({params, request}) => {
    const {projectId = ''} = params
    const entryPoints: ThinNestedEntryPointParamsObject<NestedEntryPoints> = {}
    const url = new URL(request.url)
    const {projectTab} = queryToObject(url.search)
    switch (projectTab) {
      case ProjectPageTabNamesEnum.INVESTIGATIONS:
        entryPoints.investigationsTab = {
          entryPoint: ProjectInvestigationsTabEntryPoint,
          entryPointParams: projectId,
        }
        break
      default:
    }

    return {
      queries: {
        main: {
          parameters: projectPageQuery,
          variables: {locator: `id:${projectId}`},
          options: {networkCacheConfig: {metadata: {essential: true}}},
        },
      },
      entryPoints,
    }
  },
}
