import type {Branch} from '../../../services/rest'
import type {BuildTypeId, ProjectId, ProjectOrBuildTypeNode} from '../../../types'

export const Sizes = {
  S: 14,
  M: 20,
}
export enum EntityIconType {
  PROJECT = 'project',
  BUILD_TYPE = 'buildType',
  TEMPLATE = 'template',
  INVESTIGATION = 'investigation',
  VCS_ROOT = 'vcs-root',
  PAUSED_OUTLINE = 'paused-outline',
  PAUSED = 'paused',
}

export type OverviewHeaderIconOwnProps = {
  showPausedInStatus?: boolean
  showInvestigationInStatus?: boolean
  ignoreStatus?: boolean
  size?: keyof typeof Sizes
  className?: string | null | undefined
  title?: string
  arrowClassName?: string | null | undefined
  projectOrBuildTypeNode?: ProjectOrBuildTypeNode | null | undefined
  withArrow?: boolean
}
export type OverviewHeaderIconAddProps =
  | {
      type: EntityIconType.PROJECT
      id?: ProjectId
      branch?: Branch
    }
  | {
      type: EntityIconType.BUILD_TYPE
      id?: BuildTypeId
      branch?: Branch
      showInfoStatus?: boolean
    }
  | {
      type?: Exclude<EntityIconType, EntityIconType.PROJECT | EntityIconType.BUILD_TYPE> | null
    }
export type OverviewStatusIconOwnProps = OverviewHeaderIconOwnProps & OverviewHeaderIconAddProps
export type OverviewStatusIconStateProps = {
  title?: string | null | undefined
  status?: 'failed' | 'successful' | null
  type: EntityIconType | null | undefined
  composite?: boolean
}
export type DefaultProps = {
  type: EntityIconType
  size: keyof typeof Sizes
}
export type Props = OverviewStatusIconOwnProps & OverviewStatusIconStateProps & DefaultProps
