import {graphql} from 'react-relay'
import type {ConcreteRequest} from 'relay-runtime'

export const projectPageQuery = graphql`
  query ProjectPageQuery($locator: String!) {
    project(projectLocator: $locator) {
      internalId
      ...ProjectPageFragment
    }
  }
` as ConcreteRequest
