import {Workbox} from 'workbox-window'

import {base_uri, internalProps} from '../types/BS_types'

import {CHECK_VERSION} from './sw.constants'

const useServiceWorker = internalProps['teamcity.ui.useServiceWorkers']
const url = process.env.NODE_ENV === 'development' ? '/sw.js' : `${base_uri}/sw.js`

export const cleanServiceWorkerCaches = () => {
  try {
    caches
      .keys()
      .then(cacheNames => Promise.all(cacheNames.map(cacheName => caches.delete(cacheName))))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error cleaning caches', e)
  }
}

export const unregisterServiceWorker = async () => {
  if (navigator.serviceWorker) {
    cleanServiceWorkerCaches()
    const registrations = await navigator.serviceWorker.getRegistrations()

    for (const registration of registrations) {
      registration.unregister()
    }
  }
}

const SECOND = 1000
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const RANDOM = Math.random() * 30
const RELOAD_TIMEOUT = Math.floor(RANDOM) * SECOND
let refreshing = false
let wb: Workbox | null = null
export const getWorkBoxInstance = (): Workbox | null => wb

export const registerServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    wb = new Workbox(url)
    wb.register()
    wb.messageSW({
      type: CHECK_VERSION,
      version: process.env.BUILD_NUMBER ?? ('Local' as string),
    })

    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (!refreshing) {
          refreshing = true
          setTimeout(
            () => window.location.reload(),
            document.visibilityState === 'visible' ? 0 : RELOAD_TIMEOUT,
          )
        }
      })
    }
  }
}

export const setupServiceWorker = () => {
  if (useServiceWorker) {
    registerServiceWorker()
  } else {
    unregisterServiceWorker()
  }
}
