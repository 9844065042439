import type {KeyValue} from '../../../../../utils/object'
import type {YamlDiagnostic, StepTypes} from '../../types'

import type {
  PipelineDraftState,
  PipelineErrorState,
  PipelineFormState,
} from './EditPipelinePage.slices.types'

export const initialState: PipelineDraftState = {}

export const pipelineErrorInitialState: PipelineErrorState = {
  errors: [],
}

export const collapsedBlocksInitialState: Record<string, boolean> = {}

export const pipelineFormInitialState: PipelineFormState = {}

export const pipelineYamlInitialState: KeyValue<
  string,
  {yaml?: string; diagnostics?: YamlDiagnostic[]}
> = {}

export const MIN_PARALLELISM_COUNT = 2

export const suggestionsInitialState: {
  skippedSuggestions: Record<string, string[]>
  successMessages: Record<string, StepTypes | null>
} = {
  skippedSuggestions: {},
  successMessages: {},
}
