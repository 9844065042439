import {fetchArchivedProjects} from '../../../actions/projects'
import type {AppThunk} from '../../../actions/types'
import {federationServersArg} from '../../../rest/federationServers'
import {getFederationProjectsArg} from '../../../rest/projects'
import {restApi} from '../../../services/rest'
import {sidebar} from '../../App/SidebarPanel/SidebarPanelContent/ProjectsSidebar/ProjectsSidebar.slices'

export const fetchProjectsForAllFederationServers =
  (): AppThunk<any> => async (dispatch, getState) => {
    const state = getState()

    const {data} = await dispatch(
      restApi.endpoints.getAllFederationServers.initiate(federationServersArg),
    )

    if (data != null) {
      for (let i = 0; i < data.length; i++) {
        const federationServerId = data[i]
        if (federationServerId != null) {
          dispatch(
            restApi.endpoints.getFederationProjectsNormalized.initiate(
              getFederationProjectsArg(state, federationServerId),
            ),
          )
        }
      }
    }
  }
export const toggleArchivedProjectsInSidebar = (): AppThunk<any> => (dispatch, getState) => {
  dispatch(sidebar.actions.toggleArchivedProjectsInSidebar())
  const state = getState()

  if (state.sidebar.showArchivedProjects) {
    dispatch(fetchArchivedProjects())
    dispatch(fetchProjectsForAllFederationServers())
  }
}
